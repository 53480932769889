import { Inter } from 'next/font/google'

export const HEADER_HEIGHT = '96px'
export const HEADER_TOP_PADDING = '31px'

export const SPACING_FROM_HEADER = '0px'

export const HOURS_TOGGLE_WIDTH = '50px'
export const HOURS_TOGGLE_HEIGHT = '25px'
export const MOBILE_FOOTER_HEIGHT = '71px'

export enum zIndex {
	BASE = 1,
	HEADER = 10,
	DIALOG_OVERLAY = 50,
	MOBILE_FOOTER = 51,
	DRAWER = 65,
	MARKET_DROPDOWN = 40,
}

export const STAKING_DISABLED = true
export const FEEREBATE_DISABLED = true

export const DASHBOARD_SECTION_HEIGHT = 335
export const DASHBOARD_SECTION_HEIGHT_PX = `${DASHBOARD_SECTION_HEIGHT}px`

export const DEFAULT_FONT = Inter({ subsets: ['latin'] })
