import Wei, { wei } from '@kwenta/wei';
import { DEFAULT_CRYPTO_DECIMALS, DEFAULT_FIAT_DECIMALS, DEFAULT_NUMBER_DECIMALS, FIAT_SYNTHS, UNIT_BIG_INT, ZERO_WEI, } from '../constants/number';
import { formatUnits, parseUnits } from 'viem';
import { FuturesMarketAsset } from '../types';
const thresholds = [
    { value: 1e12, divisor: 1e12, unit: 'T', decimals: 2 },
    { value: 1e9, divisor: 1e9, unit: 'B', decimals: 2 },
    { value: 1e6, divisor: 1e6, unit: 'M', decimals: 2 },
    { value: 1e3, divisor: 1e3, unit: 'K', decimals: 0 },
];
export const SHORT_CRYPTO_CURRENCY_DECIMALS = 4;
export const LONG_CRYPTO_CURRENCY_DECIMALS = 8;
export const getDecimalPlaces = (value) => (value.toString().split('.')[1] || '').length;
export const truncateNumbers = (value, maxDecimalDigits) => {
    if (value.toString().includes('.')) {
        const parts = value.toString().split('.');
        return `${parts[0]}.${parts[1].slice(0, maxDecimalDigits)}`;
    }
    return value.toString();
};
const DecimalsForAsset = {
    [FuturesMarketAsset.STETHETH]: 8,
    [FuturesMarketAsset.DOGE]: 5,
};
// From ethers.js https://github.com/ethers-io/ethers.js/blob/master/packages/units/src.ts/index.ts#L23
export function commify(value) {
    const comps = String(value).split('.');
    if (comps.length > 2 ||
        !comps[0].match(/^-?[0-9]*$/) ||
        (comps[1] && !comps[1].match(/^[0-9]*$/)) ||
        value === '.' ||
        value === '-.') {
        throw new Error(`invalid value ${value}`);
    }
    // Make sure we have at least one whole digit (0 if none)
    let whole = comps[0];
    let negative = '';
    if (whole.substring(0, 1) === '-') {
        negative = '-';
        whole = whole.substring(1);
    }
    // Make sure we have at least 1 whole digit with no leading zeros
    while (whole.substring(0, 1) === '0') {
        whole = whole.substring(1);
    }
    if (whole === '') {
        whole = '0';
    }
    let suffix = '';
    if (comps.length === 2) {
        suffix = `.${comps[1] || '0'}`;
    }
    while (suffix.length > 2 && suffix[suffix.length - 1] === '0') {
        suffix = suffix.substring(0, suffix.length - 1);
    }
    const formatted = [];
    while (whole.length) {
        if (whole.length <= 3) {
            formatted.unshift(whole);
            break;
        }
        else {
            const index = whole.length - 3;
            formatted.unshift(whole.substring(index));
            whole = whole.substring(0, index);
        }
    }
    return negative + formatted.join(',') + suffix;
}
/**
 * ethers utils.commify method will reduce the decimals of a number to one digit if those decimals are zero.
 * This helper is used to reverse this behavior in order to display the specified decimals in the output.
 *
 * ex: utils.commify('10000', 2) => '10,000.0'
 * ex: commifyAndPadDecimals('10000', 2)) => '10,000.00'
 * @param value - commified value from utils.commify
 * @param decimals - number of decimals to display on commified value.
 * @returns string
 */
export const commifyAndPadDecimals = (value, decimals) => {
    let formatted = commify(value);
    const comps = formatted.split('.');
    if (!decimals)
        return comps[0];
    if (comps.length === 2 && comps[1].length !== decimals) {
        const zeros = '0'.repeat(decimals - comps[1].length);
        const decimalSuffix = `${comps[1]}${zeros}`;
        formatted = `${comps[0]}.${decimalSuffix}`;
    }
    return formatted;
};
const getDecimalsForFormatting = (value, options) => {
    if (options?.truncation)
        return options?.truncation.decimals;
    if (options?.suggestDecimalsForAsset) {
        const decimals = DecimalsForAsset[options.suggestDecimalsForAsset];
        return decimals ?? suggestedDecimals(value);
    }
    if (options?.suggestDecimals)
        return suggestedDecimals(value);
    return options?.minDecimals ?? DEFAULT_NUMBER_DECIMALS;
};
export const formatNumber = (value, options) => {
    const prefix = options?.prefix;
    const suffix = options?.suffix;
    const truncateThreshold = options?.truncateOver ?? 0;
    const truncateBelowDecimals = options?.truncateBelowDecimals;
    let truncation = options?.truncation;
    let weiValue = wei(0);
    try {
        weiValue = wei(value);
    }
    catch (_e) { }
    if (truncateBelowDecimals && weiValue.abs().lt(wei(10 ** -truncateBelowDecimals))) {
        weiValue = wei(0);
    }
    const isNegative = weiValue.lt(wei(0));
    const formattedValue = [];
    if (isNegative) {
        formattedValue.push('-');
    }
    if (prefix) {
        formattedValue.push(prefix);
    }
    // specified truncation params overrides universal truncate
    truncation =
        truncateThreshold && !truncation
            ? thresholds.find((threshold) => weiValue.gte(threshold.value) && weiValue.gte(truncateThreshold))
            : truncation;
    let weiBeforeAsString = truncation ? weiValue.abs().div(truncation.divisor) : weiValue.abs();
    const defaultDecimals = getDecimalsForFormatting(weiBeforeAsString, { ...options, truncation });
    const decimals = options?.maxDecimals !== undefined
        ? Math.min(defaultDecimals, options.maxDecimals)
        : defaultDecimals;
    if (options?.floor) {
        weiBeforeAsString = wei(truncateNumbers(weiBeforeAsString, decimals));
    }
    const withCommas = commifyAndPadDecimals(weiBeforeAsString.toString(decimals), decimals);
    formattedValue.push(withCommas);
    if (truncation) {
        formattedValue.push(truncation.unit);
    }
    if (suffix) {
        formattedValue.push(` ${suffix}`);
    }
    const res = formattedValue.join('');
    if (options?.removeTrailingZeros) {
        return stripZeros(res);
    }
    if (options?.maskAsSensitive) {
        return res.replace(/./g, '*');
    }
    return res;
};
export const formatCryptoCurrency = (value, options) => formatNumber(value, {
    prefix: options?.sign,
    suffix: options?.currencyKey,
    minDecimals: options?.minDecimals ?? DEFAULT_CRYPTO_DECIMALS,
    ...options,
});
export const formatFiatCurrency = (value, options) => formatNumber(value, {
    ...options,
    prefix: options?.sign,
    suffix: options?.currencyKey,
    minDecimals: options?.minDecimals ?? DEFAULT_FIAT_DECIMALS,
});
export const isFiatCurrency = (currencyKey) => FIAT_SYNTHS.has(currencyKey);
export const formatCurrency = (currencyKey, value, options) => isFiatCurrency(currencyKey)
    ? formatFiatCurrency(value, options)
    : formatCryptoCurrency(value, options);
export const formatDollars = (value, options) => formatCurrency('sUSD', value, { sign: '$', ...options });
export const formatPercent = (value, options) => {
    const weiValue = wei(value).mul(100);
    let decimals = options?.suggestDecimals ? suggestedDecimals(weiValue) : options?.minDecimals ?? 2;
    if (options?.maxDecimals) {
        decimals = Math.min(decimals, options.maxDecimals);
    }
    if (options?.minDecimals) {
        decimals = Math.max(decimals, options.minDecimals);
    }
    return `${weiValue.toString(decimals)}%`;
};
export function scale(input, decimalPlaces) {
    return input.mul(wei(10).pow(decimalPlaces));
}
export const formatGwei = (wei) => wei / 1e8 / 10;
export const divideDecimal = (x, y) => {
    return (x * UNIT_BIG_INT) / y;
};
export const multiplyDecimal = (x, y) => {
    return (x * y) / UNIT_BIG_INT;
};
export const weiFromWei = (weiAmount) => {
    if (weiAmount instanceof Wei) {
        const precisionDiff = 18 - weiAmount.p;
        return wei(weiAmount, 18, true).div(10 ** precisionDiff);
    }
    else {
        return wei(weiAmount, 18, true);
    }
};
export const suggestedDecimals = (value) => {
    const absValue = wei(value).abs().toNumber();
    if (absValue >= 100000)
        return 0;
    if (absValue >= 100 || absValue === 0)
        return 2;
    if (absValue >= 10)
        return 3;
    if (absValue >= 0.1)
        return 4;
    if (absValue >= 0.01)
        return 5;
    if (absValue >= 0.001)
        return 6;
    if (absValue >= 0.0001)
        return 7;
    if (absValue >= 0.00001)
        return 8;
    return 11;
};
export const floorNumber = (num, decimals) => {
    const precision = 10 ** (decimals ?? suggestedDecimals(num));
    return Math.floor(Number(num) * precision) / precision;
};
export const ceilNumber = (num, decimals) => {
    const precision = 10 ** (decimals ?? suggestedDecimals(num));
    return Math.ceil(Number(num) * precision) / precision;
};
// Converts to string but strips trailing zeros
export const weiToString = (weiVal) => {
    return String(Number.parseFloat(weiVal.toString()));
};
export const isZero = (num) => {
    return wei(num || 0).eq(0);
};
export const weiFromEth = (num) => wei(num).toString();
export const gweiToWei = (val) => {
    return parseUnits(wei(val).toString(), 9).toString();
};
export const toWei = (value, p) => {
    return value ? wei(value, p) : ZERO_WEI;
};
export const stripZeros = (value) => {
    if (!value)
        return '';
    return String(value).replace(/(\.[0-9]*[1-9])0+$|\.0*$/, '$1');
};
export const bigIntMax = (a, b) => (a > b ? a : b);
export const bigIntMin = (a, b) => (a < b ? a : b);
export const parseUsdcValue = (value) => {
    return parseUnits(String(ceilNumber(value, 6)), 6);
};
export const formatUsdcValue = (value) => {
    return formatUnits(BigInt(ceilNumber(value, 12)), 12);
};
export const microPowerTwoTransform = (value) => {
    return value.mul(value).div(1000000);
};
export const centimilliPowerTwoTransform = (value) => {
    return value.mul(value).div(100000);
};
export const inverseScaledBy1000 = (value) => {
    return wei('1').div(value).mul(1000);
};
export const millionScaleTransform = (value) => {
    return value.mul(1000000);
};
export const tenScaleTransform = (value) => {
    return value.mul(10);
};
