import { wei } from '@kwenta/wei';
import { decodeFunctionResult, encodeFunctionData, encodePacked, formatEther, parseUnits, stringToHex, toHex, zeroAddress, } from 'viem';
import { COMMON_ADDRESSES, SNX_V3_PERPS_ADDRESSES, TOKEN_ADDRESSES, V3_WRAPPED_TOKEN_MARKETS, ZERO_BIG_INT, ZERO_WEI, } from '../constants';
import { UNSUPPORTED_NETWORK } from '../constants/errors';
import { KWENTA_TRACKING_CODE, NEWLY_LISTED_MARKETS, PYTH_IDS_BY_ASSET, SynthAssetKeysV3, } from '../constants/futures';
import { PERIOD_IN_HOURS, PERIOD_IN_SECONDS } from '../constants/period';
import { DepositableAssetKeysV3, KWENTA_PERPS_V3_REFERRAL_ADDRESS, ORDERS_KEEPER_ADDRESSES, UNISWAP_POOL_FEES, V3_PERPS_MARKET_IDS_TO_KEYS, WRAP_SLIPPAGE_MULTIPLIER, ZAP_LOAN_BUFFER, predefinedOracles, tokenTickerToPriceAsset, v3MarketIdByMarketAsset, v3PerpsMarketIdToAssetKey, } from '../constants/perpsv3';
import { commonContractsByNetwork, snxV3ContractsByNetwork, tokenContractsByNetwork, } from '../contracts';
import MarginEngineArb from '../contracts/abis/MarginEngineArb';
import MarginEngineBase from '../contracts/abis/MarginEngineBase';
import PerpsMarketV3Abi from '../contracts/abis/PerpsV3MarketProxy';
import UniswapFactoryAbi from '../contracts/abis/UniswapFactory';
import UniswapPoolAbi from '../contracts/abis/UniswapPool';
import WethAbi from '../contracts/abis/WETH';
import ZapAbi from '../contracts/abis/Zap';
import { queryAllPerpsV3PositionLiquidations, queryAllPerpsV3SettledOrders, queryCollateralChanges, queryDelegatesForAccount, queryFundingRateHistory, queryOrderSettleds, queryPerpsV3DailyVolume, queryPerpsV3PositionLiquidations, queryPerpsV3Positions, queryPerpsV3SettledOrders, queryPnlSnapshots, querySettlementStrategies, querySubAccountsForAccount, } from '../queries/perpsV3';
import { FuturesMarginType, FuturesMarketAsset, PerpsProvider, } from '../types';
import { ConditionalOrderStatusV3, } from '../types/perpsV3';
import { calculateTimestampForPeriod, ceilNumber, formatUsdcValue, notNill, parseUsdcValue, tokenDecimals, weiFromWei, } from '../utils';
import { DepositTokenToSynth, MarketAssetByKey, appAdjustedLeverage, getMarketCategory, getMarketName, mapCollateralChanges, } from '../utils/futures';
import { calculatePerpsV3Volumes, chainIsBase, chainToV3Provider, formatSettlementStrategy, formatV3AsyncOrder, mapPerpsV3Position, mergeSnxV3LiquidationTrades, mergeSnxV3PositionsData, orderConditionsToCallData, queryOdos, reconcileOrders, serializeConditionalOrder, } from '../utils/perpsV3';
import { getReasonFromCode } from '../utils/token';
import { depositableAssetToToken } from '../utils/token';
import { sqrtToPrice } from '../utils/uniswap';
import { multicallReads } from './calls';
export default class PerpsV3Service {
    constructor(sdk) {
        this.uniswapPools = {
            42161: {},
            421614: {},
            84532: {},
            8453: {},
        };
        this.settlementStrategies = {};
        this.sendErc7412Transaction = async ({ calls, useMarginEngine, chainId, }) => {
            const { walletAddress } = this.sdk.context;
            const engineType = chainToV3Provider(chainId) === PerpsProvider.SNX_V3_BASE
                ? 'MarginEngineBase'
                : 'MarginEngineArb';
            const contractName = useMarginEngine ? engineType : 'PerpsV3MarketProxy';
            const contract = this.contractConfigs(chainId)[contractName];
            const txs = calls.map((c) => {
                if (!contract)
                    throw new Error(UNSUPPORTED_NETWORK);
                let data;
                switch (contractName) {
                    case 'PerpsV3MarketProxy':
                        data = encodeFunctionData({
                            abi: PerpsMarketV3Abi,
                            functionName: c.functionName,
                            args: c.params,
                        });
                        break;
                    case 'MarginEngineArb':
                        data = encodeFunctionData({
                            abi: MarginEngineArb,
                            functionName: c.functionName,
                            args: c.params,
                        });
                        break;
                    case 'MarginEngineBase':
                        data = encodeFunctionData({
                            abi: MarginEngineBase,
                            functionName: c.functionName,
                            args: c.params,
                        });
                        break;
                }
                return {
                    to: contract.address,
                    from: walletAddress,
                    value: c.value ? BigInt(c.value) : BigInt(0),
                    data: data,
                };
            });
            const res = await this.sdk.calls.erc7412Write(txs, chainId, {
                useMarginEngine: useMarginEngine ? 'internal' : undefined,
            });
            return res;
        };
        this.batchMarketCalls = async (calls, networkId, oracleIds = []) => {
            const configs = this.contractConfigs(networkId);
            const PerpsV3MarketProxy = configs?.PerpsV3MarketProxy;
            const abi = PerpsV3MarketProxy.abi;
            if (!PerpsV3MarketProxy)
                throw new Error(UNSUPPORTED_NETWORK);
            const encoded = calls.map((m) => ({
                to: PerpsV3MarketProxy.address,
                data: encodeFunctionData({
                    abi: abi,
                    functionName: m.functionName,
                    args: m.args,
                }),
                value: ZERO_BIG_INT,
            }));
            const multiCalls = multicallReads(encoded, networkId);
            const results = await this.sdk.calls.erc7412Read([multiCalls], networkId, {
                ids: oracleIds,
                prepend: true,
            });
            return results.map((r, i) => {
                const call = calls[i];
                // @ts-ignore TODO: Fix types
                return decodeFunctionResult({
                    abi: abi,
                    functionName: call.functionName,
                    data: r.returnData,
                });
            });
        };
        this.sdk = sdk;
    }
    contractConfigs(chainId) {
        return {
            ...snxV3ContractsByNetwork(chainId),
            ...commonContractsByNetwork(chainId),
            ...tokenContractsByNetwork(chainId),
        };
    }
    client(chainId) {
        const client = this.sdk.context.clients[chainId];
        if (client)
            return client;
        throw new Error(UNSUPPORTED_NETWORK);
    }
    async getMarkets(chainId) {
        const strategies = await this.getSettlementStrategies(chainId);
        const client = this.client(chainId);
        const perpsV3MarketProxy = this.contractConfigs(chainId)?.PerpsV3MarketProxy;
        if (!perpsV3MarketProxy || !client)
            throw new Error(UNSUPPORTED_NETWORK);
        const markets = await client.readContract({
            ...perpsV3MarketProxy,
            functionName: 'getMarkets',
        });
        const perpsV3Markets = markets.filter((m) => m !== 6300n);
        const interestRate = await client.readContract({
            ...perpsV3MarketProxy,
            functionName: 'interestRate',
        });
        const calls = perpsV3Markets.reduce((acc, m) => {
            acc.push(...[
                {
                    functionName: 'getFundingParameters',
                    args: [m],
                },
                {
                    functionName: 'getLiquidationParameters',
                    args: [m],
                },
                {
                    functionName: 'getMaxLiquidationParameters',
                    args: [m],
                },
                {
                    functionName: 'getOrderFees',
                    args: [m],
                },
                {
                    functionName: 'getMaxMarketSize',
                    args: [m],
                },
                {
                    functionName: 'getMaxMarketValue',
                    args: [m],
                },
                {
                    functionName: 'getMarketSummary',
                    args: [m],
                },
            ]);
            return acc;
        }, []);
        const results = await this.batchMarketCalls(calls, chainId, predefinedOracles(chainId));
        const futuresData = results.reduce((acc, res, i) => {
            const type = i % 7;
            switch (type) {
                case 0:
                    acc.fundingParams.push(res);
                    break;
                case 1:
                    acc.liquidationParams.push(res);
                    break;
                case 2:
                    acc.maxLiquidationParams.push(res);
                    break;
                case 3:
                    acc.fees.push(res);
                    break;
                case 4:
                    acc.maxMarketSizes.push(res);
                    break;
                case 5:
                    acc.maxMarketValues.push(res);
                    break;
                case 6:
                    acc.marketSummaries.push(res);
                    break;
            }
            return acc;
        }, {
            fundingParams: [],
            liquidationParams: [],
            maxLiquidationParams: [],
            fees: [],
            maxMarketSizes: [],
            maxMarketValues: [],
            marketSummaries: [],
        });
        const { fundingParams, liquidationParams, maxLiquidationParams, fees, maxMarketSizes, maxMarketValues, marketSummaries, } = futuresData;
        const futuresMarkets = marketSummaries.reduce((acc, summary, i) => {
            const [makerFee, takerFee] = fees[i];
            const perpsMarketId = perpsV3Markets[i].toString();
            const [skewScale] = fundingParams[i];
            const [initialMarginRatioD18, minimumInitialMarginRatioD18, minimumPositionMargin, maintenanceMarginScalarD18, flagRewardRatioD18,] = liquidationParams[i];
            const [maxLiquidationLimitAccumulationMultiplier, maxSecondsInLiquidationWindow] = maxLiquidationParams[i];
            const maxMarketSize = maxMarketSizes[i];
            const maxMarketValue = maxMarketValues[i];
            const marketKey = V3_PERPS_MARKET_IDS_TO_KEYS[chainToV3Provider(chainId)][Number(perpsMarketId)];
            if (!marketKey)
                return acc;
            const asset = MarketAssetByKey[marketKey];
            const marketSize = weiFromWei(summary.size.toString());
            const marketSkew = weiFromWei(summary.skew.toString());
            const maxOpenInterest = weiFromWei(summary.maxOpenInterest.toString());
            const indexPrice = weiFromWei(summary.indexPrice.toString());
            const makerFeeWei = weiFromWei(makerFee);
            const takerFeeWei = weiFromWei(takerFee);
            const maxMarketSizeWei = weiFromWei(maxMarketSize.toString());
            const maxMarketValueWei = weiFromWei(maxMarketValue.toString());
            const calculatedMaxMarketValue = maxMarketSizeWei.mul(indexPrice);
            const limitUsd = maxMarketValueWei.gt(calculatedMaxMarketValue)
                ? calculatedMaxMarketValue
                : maxMarketValueWei;
            const strategy = strategies.filter((s) => s.marketId === perpsMarketId)[0];
            acc.push({
                provider: chainToV3Provider(chainId),
                marginType: FuturesMarginType.CROSS_MARGIN,
                marketId: perpsMarketId.toString(),
                marketName: getMarketName(asset) ?? marketKey,
                category: getMarketCategory(marketKey),
                settlementStrategies: strategies.filter((s) => s.marketId === perpsMarketId),
                asset: asset,
                currentFundingRate: weiFromWei(summary.currentFundingRate.toString()).div('24'),
                settlementFee: strategy?.settlementReward ?? wei(0),
                feeRates: {
                    makerFee: makerFeeWei,
                    takerFee: takerFeeWei,
                },
                openInterest: {
                    shortPct: marketSize.eq(0)
                        ? 0
                        : marketSize.sub(marketSkew).div('2').div(marketSize).toNumber(),
                    longPct: wei(marketSize).eq(0)
                        ? 0
                        : marketSize.add(marketSkew).div('2').div(marketSize).toNumber(),
                    shortUSD: wei(marketSize).eq(0)
                        ? wei(0)
                        : wei(marketSize).sub(marketSkew).div('2').mul(indexPrice),
                    longUSD: wei(marketSize).eq(0)
                        ? wei(0)
                        : wei(marketSize).add(marketSkew).div('2').mul(indexPrice),
                    long: wei(marketSize).add(marketSkew).div('2'),
                    short: wei(marketSize).sub(marketSkew).div('2'),
                },
                marketDebt: wei(0),
                marketSkew: marketSkew,
                appMaxLeverage: appAdjustedLeverage(wei(25)),
                marketLimitUsd: {
                    long: limitUsd,
                    short: limitUsd,
                },
                marketLimitNative: {
                    long: maxOpenInterest,
                    short: maxOpenInterest,
                },
                minInitialMargin: wei(100), // TODO: Is this still relevant in v3
                isSuspended: false, // TODO: Assign suspensions
                marketClosureReason: getReasonFromCode(2), // TODO: Map closure reason
                settings: {
                    initialMarginRatio: weiFromWei(initialMarginRatioD18),
                    skewScale: weiFromWei(skewScale),
                    minimumInitialMarginRatio: weiFromWei(minimumInitialMarginRatioD18),
                    minimumPositionMargin: weiFromWei(minimumPositionMargin),
                    maintenanceMarginScalar: weiFromWei(maintenanceMarginScalarD18),
                    maxLiquidationLimitAccumulationMultiplier: weiFromWei(maxLiquidationLimitAccumulationMultiplier),
                    maxSecondsInLiquidationWindow: wei(maxSecondsInLiquidationWindow.toString()),
                    flagRewardRatio: weiFromWei(flagRewardRatioD18),
                    interestRate: weiFromWei(interestRate),
                },
                newListing: !!NEWLY_LISTED_MARKETS[chainId]?.includes(marketKey),
            });
            return acc;
        }, []);
        return futuresMarkets;
    }
    async getPositions(params) {
        const { accountId, futuresMarkets, chainId, status, options } = params;
        const positions = await queryPerpsV3Positions(chainId, accountId, status ?? 'all', options);
        const activePositions = await this.getActivePositions(BigInt(accountId), futuresMarkets.map((fm) => fm.id), chainId);
        const marginInfo = await this.getAccountMarginInfo(BigInt(accountId), chainId);
        const markPrices = await this.getMarketPrices(chainId);
        return mergeSnxV3PositionsData(positions, activePositions, marginInfo, futuresMarkets, markPrices);
    }
    async getActivePositions(accountId, marketIds, chainId) {
        if (!marketIds.length)
            return [];
        const positionDetails = (await this.batchMarketCalls(marketIds.map((id) => ({
            functionName: 'getOpenPosition',
            args: [accountId, id],
        })), chainId, predefinedOracles(chainId)));
        const positions = positionDetails.reduce((acc, res, i) => {
            const pos = mapPerpsV3Position(chainId, marketIds[i], ...res);
            if (pos)
                acc.push(pos);
            return acc;
        }, []);
        return positions;
    }
    async getSupportedCollaterals(chainId) {
        const perpsV3MarketProxyMultiC = this.contractConfigs(chainId)?.PerpsV3MarketProxy;
        const collaterals = await this.client(chainId).readContract({
            ...perpsV3MarketProxyMultiC,
            functionName: 'getSupportedCollaterals',
        });
        const ids = V3_WRAPPED_TOKEN_MARKETS[chainId];
        return collaterals.reduce((acc, c) => {
            const key = Object.keys(ids).find((key) => ids[key] === Number(c));
            if (key) {
                acc.push(key);
            }
            return acc;
        }, []);
    }
    async getCollateralBalances(accountId, chainId) {
        const ids = V3_WRAPPED_TOKEN_MARKETS[chainId];
        const idKeys = Object.keys(ids);
        const results = await this.batchMarketCalls(idKeys.map((id) => ({
            functionName: 'getCollateralAmount',
            args: [accountId, ids[id]],
        })), chainId);
        return results.map((res, i) => ({
            synthId: idKeys[i],
            amount: wei(res),
        }));
    }
    async getMarketPrices(chainId) {
        const markets = this.markets ?? (await this.getMarkets(chainId));
        return markets.reduce((acc, curr) => {
            try {
                const prices = this.sdk.prices.getOffchainPrice(curr.asset);
                acc[curr.asset] = prices;
                return acc;
            }
            catch {
                return acc;
            }
        }, {});
    }
    async getMarketFundingRatesHistory(marketAsset, chainId, periodLength = PERIOD_IN_SECONDS.TWO_WEEKS) {
        const minTimestamp = Math.floor(Date.now() / 1000) - periodLength;
        return queryFundingRateHistory(chainId, marketAsset, minTimestamp);
    }
    async getSettlementStrategies(chainId) {
        const existing = this.settlementStrategies[chainId];
        if (existing && existing.lastUpdated > Date.now() - PERIOD_IN_SECONDS.ONE_HOUR * 1000) {
            return existing.strategies;
        }
        const strategies = await querySettlementStrategies(chainId);
        const formattedStrats = strategies.map(formatSettlementStrategy);
        this.settlementStrategies[chainId] = {
            lastUpdated: Date.now(),
            strategies: formattedStrats,
        };
        return formattedStrats;
    }
    async getDailyVolumes(chainId) {
        const minTimestamp = Math.floor(calculateTimestampForPeriod(PERIOD_IN_HOURS.ONE_DAY) / 1000);
        const response = await queryPerpsV3DailyVolume(chainId, minTimestamp);
        return response ? calculatePerpsV3Volumes(response) : {};
    }
    async getAccounts(walletAddress, marginEngineEnabled, chainId) {
        const { PerpsV3AccountProxy, PerpsV3MarketProxy, MarginEngineBase, MarginEngineArb } = this.contractConfigs(chainId);
        const marginEngineAddr = chainToV3Provider(chainId) === PerpsProvider.SNX_V3_ARB
            ? MarginEngineArb?.address
            : MarginEngineBase?.address;
        if (!PerpsV3AccountProxy || !PerpsV3MarketProxy || !marginEngineAddr)
            throw new Error(UNSUPPORTED_NETWORK);
        if (!walletAddress)
            return [];
        const accountCount = await this.client(chainId).readContract({
            ...PerpsV3AccountProxy,
            functionName: 'balanceOf',
            args: [walletAddress],
        });
        const calls = Number(accountCount) > 0
            ? [...Array(Number(accountCount)).keys()].map((index) => {
                return {
                    ...PerpsV3AccountProxy,
                    functionName: 'tokenOfOwnerByIndex',
                    args: [walletAddress, index],
                };
            })
            : [];
        const accountIds = await this.client(chainId).multicall({
            allowFailure: false,
            contracts: calls,
        });
        const permissionCalls = accountIds.map((res) => {
            return {
                ...PerpsV3MarketProxy,
                functionName: 'hasPermission',
                args: [res, stringToHex('ADMIN', { size: 32 }), marginEngineAddr],
            };
        });
        const permissions = marginEngineEnabled
            ? // @ts-ignore Cannot figure out the type issue
                await this.client(chainId).multicall({
                    allowFailure: false,
                    contracts: permissionCalls,
                })
            : [];
        return accountIds.map((id, i) => ({
            accountId: id,
            // @ts-ignore
            marginEnginePermitted: !!permissions[i],
        }));
    }
    async getAccountOwner(id, chainId) {
        const { PerpsV3MarketProxy } = this.contractConfigs(chainId);
        if (!PerpsV3MarketProxy)
            throw new Error(UNSUPPORTED_NETWORK);
        const owner = await this.client(chainId).readContract({
            ...PerpsV3MarketProxy,
            functionName: 'getAccountOwner',
            args: [BigInt(id)],
        });
        if (owner === zeroAddress)
            return null;
        return owner;
    }
    async getAccountMarginInfo(accountId, chainId) {
        const calls = [
            { functionName: 'getAvailableMargin', args: [accountId] },
            { functionName: 'getRequiredMargins', args: [accountId] },
            { functionName: 'debt', args: [accountId] },
        ];
        const [availableMargin, margins, debt] = (await this.batchMarketCalls(calls, chainId, predefinedOracles(chainId)));
        const availableMarginWei = weiFromWei(availableMargin.toString());
        const initialMarginWei = weiFromWei(margins[0].toString());
        const liqRewardWei = weiFromWei(margins[2].toString());
        const withdrawableMargin = availableMarginWei.sub(initialMarginWei.add(liqRewardWei));
        return {
            availableMargin: availableMarginWei,
            withdrawableMargin: withdrawableMargin,
            requiredInitialMargin: initialMarginWei,
            requiredMaintenanceMargin: weiFromWei(margins[1].toString()),
            maxLiquidationReward: liqRewardWei,
            debt: weiFromWei(debt?.toString() ?? 0),
        };
    }
    async getAccountUsdcCredit(accountId, chainId) {
        const { MarginEngineBase } = this.contractConfigs(chainId);
        const client = this.client(chainId);
        if (!MarginEngineBase || !client)
            throw new Error(UNSUPPORTED_NETWORK);
        const balance = await client.readContract({
            ...MarginEngineBase,
            functionName: 'credit',
            args: [accountId],
        });
        return wei(balance);
    }
    async getPendingAsyncOrder(accountId, chainId) {
        const { PerpsV3MarketProxy } = this.contractConfigs(chainId);
        if (!PerpsV3MarketProxy)
            throw new Error(UNSUPPORTED_NETWORK);
        const order = await this.client(chainId).readContract({
            ...PerpsV3MarketProxy,
            functionName: 'getOrder',
            args: [accountId],
        });
        if (order.request.sizeDelta !== ZERO_BIG_INT) {
            return formatV3AsyncOrder(order);
        }
        return;
    }
    async getTradePreview({ accountId, marketId, chainId, sizeDelta, indexPrice, }) {
        const assetKey = v3PerpsMarketIdToAssetKey(chainId, marketId);
        if (!assetKey)
            throw new Error(`Cannot find asset key for market id: ${marketId}`);
        const [[fee], fillPrice, requiredMargin] = (await this.batchMarketCalls([
            {
                functionName: 'computeOrderFeesWithPrice',
                args: [marketId, sizeDelta.toBigInt(), indexPrice.toBigInt()],
            },
            {
                functionName: 'fillPrice',
                args: [marketId, sizeDelta.toBigInt(), indexPrice.toBigInt()],
            },
            {
                functionName: 'requiredMarginForOrderWithPrice',
                args: [accountId, marketId, sizeDelta.toBigInt(), indexPrice.toBigInt()],
            },
        ], chainId, predefinedOracles(chainId)));
        const executionFee = await this.getExecutionCost(chainId);
        const { settlementReward } = (await this.getSettlementStrategies(chainId)).find((s) => s.marketId === marketId) ?? { settlementReward: ZERO_WEI };
        return {
            fillPrice: weiFromWei(fillPrice.toString()),
            fee: weiFromWei(fee.toString()),
            requiredMargin: requiredMargin ? weiFromWei(requiredMargin.toString()) : wei(0),
            settlementFee: executionFee.add(settlementReward),
        };
    }
    async getExecutionCost(chainId) {
        const { PerpsV3MarketProxy, SynthetixV3Proxy } = this.contractConfigs(chainId);
        if (!PerpsV3MarketProxy || !SynthetixV3Proxy)
            throw new Error(UNSUPPORTED_NETWORK);
        const nodeId = this.executorNodeId ??
            (await this.client(chainId).readContract({
                ...PerpsV3MarketProxy,
                functionName: 'getKeeperCostNodeId',
                args: [],
            }));
        this.executorNodeId = nodeId;
        const encoded = [
            {
                to: SynthetixV3Proxy.address,
                data: encodeFunctionData({
                    abi: SynthetixV3Proxy.abi,
                    functionName: 'process',
                    args: [nodeId],
                }),
                value: ZERO_BIG_INT,
            },
        ];
        const multiCalls = multicallReads(encoded, chainId);
        const results = await this.sdk.calls.erc7412Read([multiCalls], chainId);
        const { price } = decodeFunctionResult({
            abi: SynthetixV3Proxy.abi,
            functionName: 'process',
            data: results[0].returnData,
        });
        return wei(price);
    }
    // TODO: Support pagination
    async getTradeHistory(accountId, chainId, options) {
        const trades = await queryPerpsV3SettledOrders(chainId, { accountId: accountId.toString() }, options);
        const liquidationTrades = await queryPerpsV3PositionLiquidations(chainId, { accountId: accountId.toString() }, options);
        return mergeSnxV3LiquidationTrades(chainId, trades, liquidationTrades);
    }
    async getAllTradesByMarket(marketAsset, chainId, options) {
        const marketId = v3MarketIdByMarketAsset(chainId, marketAsset);
        return queryAllPerpsV3SettledOrders(chainId, marketId, options);
    }
    async getTradesForPosition(positionId, chainId, options) {
        const trades = await queryPerpsV3SettledOrders(chainId, { positionId }, options);
        const liquidationTrades = await queryPerpsV3PositionLiquidations(chainId, { positionId }, options);
        return mergeSnxV3LiquidationTrades(chainId, trades, liquidationTrades);
    }
    async getAllAccountLiquidations(accountId, chainId, options) {
        const liquidations = await queryPerpsV3PositionLiquidations(chainId, { accountId: accountId.toString() }, options);
        return liquidations.map((o) => ({
            ...o,
            timestamp: Number(o.timestamp),
            estimatedPrice: weiFromWei(o.estimatedPrice),
            amount: weiFromWei(o.amount),
            notionalAmount: weiFromWei(o.notionalAmount),
            liquidationPnl: weiFromWei(o.liquidationPnl),
        }));
    }
    async getAllLiquidationsByMarket(marketId, chainId, options) {
        const positionLiquidations = await queryAllPerpsV3PositionLiquidations(chainId, marketId, options);
        return positionLiquidations.map((o) => ({
            ...o,
            timestamp: Number(o.timestamp),
            estimatedPrice: weiFromWei(o.estimatedPrice),
            amount: weiFromWei(o.amount),
            notionalAmount: weiFromWei(o.notionalAmount),
            liquidationPnl: weiFromWei(o.liquidationPnl),
        }));
    }
    async getPnlSnapshots(accountId, chainId) {
        const snapshots = await queryPnlSnapshots(chainId, accountId.toString());
        return snapshots.map((s) => ({
            ...s,
            timestamp: Number(s.timestamp),
            pnl: weiFromWei(s.pnl),
        }));
    }
    async getMarginSnapshots(accountId, chainId, args) {
        const params = new URLSearchParams({
            networkId: chainId.toString(),
            accountId: accountId.toString(),
            from: args.fromTimestamp.toString(),
            to: args.toTimestamp.toString(),
            resolution: args.resolution.toString(),
        });
        const url = `${this.sdk.context.apiUrl}/perpsV3/account/${accountId}/margin-snapshots?${params.toString()}`;
        const response = await fetch(url);
        const res = await response.json();
        return res.data.marginSnapshots.map((s) => ({
            ...s,
            margin: weiFromWei(s.margin),
        }));
    }
    /**
     * @desc Get conditional orders
     * @param address - Cross margin address
     * @returns Conditional orders array
     * @example
     * ```ts
     * import { KwentaSDK } from 'kwenta-sdk';
     * const sdk = new KwentaSDK();
     * const conditionalOrders = await sdk.snxPerpsV3.getConditionalOrders('0x...');
     * console.log(conditionalOrders);
     * ```
     */
    async getConditionalOrders(accountId, chainId) {
        const params = new URLSearchParams({
            networkId: chainId.toString(),
            accountId: accountId.toString(),
        });
        const url = `${this.sdk.context.apiUrl}/perpsV3/orders?${params.toString()}`;
        const response = await fetch(url);
        const res = await response.json();
        try {
            const formatted = res.data.orders.map((o) => ({
                ...o,
                orderDetails: {
                    ...o.orderDetails,
                    marketId: String(o.orderDetails.marketId),
                    sizeDelta: formatEther(BigInt(o.orderDetails.sizeDelta)),
                    acceptablePrice: formatEther(BigInt(o.orderDetails.acceptablePrice)),
                },
                decodedConditions: {
                    isPriceAbove: o.decodedConditions.isPriceAbove
                        ? formatEther(BigInt(o.decodedConditions.isPriceAbove))
                        : undefined,
                    isPriceBelow: o.decodedConditions.isPriceBelow
                        ? formatEther(BigInt(o.decodedConditions.isPriceBelow))
                        : undefined,
                },
                maxExecutorFee: formatEther(BigInt(o.maxExecutorFee)),
            }));
            return formatted;
        }
        catch (_e) {
            throw new Error(res.data.message);
        }
    }
    /**
     * @desc Create a new conditional order and store it on the DB
     * @param params - Add conditional order params
     * @returns Created conditional order object
     */
    async createConditionalOrders(params, chainId) {
        const orders = [];
        let i = 0;
        for (const order of params) {
            const nonce = (await this.getNonce(order.accountId)) + i;
            const formattedOrder = this.inputToConditionalOrder(order, nonce);
            const signedOrder = await this.signConditionalOrder(formattedOrder, chainId);
            i++;
            orders.push({
                order: formattedOrder,
                signedOrder,
            });
        }
        const response = await fetch(`${this.sdk.context.apiUrl}/perpsV3/orders`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                orders: orders.map((o) => ({
                    ...o,
                    order: serializeConditionalOrder(o.order),
                })),
            }),
        });
        const responseBody = await response.json();
        if (!responseBody.success) {
            throw new Error(responseBody.data.message);
        }
        return responseBody.data;
    }
    async updateConditionalOrders(orderUpdates, chainId) {
        const orders = orderUpdates.map(({ inputs, nonce }) => this.inputToConditionalOrder(inputs, nonce));
        const signedOrders = await Promise.all(orders.map((order) => this.signConditionalOrder(order, chainId)));
        const orderUpdatesBody = orderUpdates.map((o, i) => ({
            orderId: o.orderId,
            newOrder: serializeConditionalOrder(orders[i]),
            signedOrder: signedOrders[i],
            newStatus: o.status,
        }));
        const response = await fetch(`${this.sdk.context.apiUrl}/perpsV3/orders`, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                orderUpdates: orderUpdatesBody,
            }),
        });
        const data = await response.json();
        return data;
    }
    async cancelConditionalOrders(ids) {
        const signedOrders = await Promise.all(ids.map((id) => this.sdk.context.walletClient.signMessage({ message: toHex(id) })));
        const orderUpdatesBody = signedOrders.map((o, i) => ({
            orderId: ids[i],
            signedOrder: o,
            newStatus: ConditionalOrderStatusV3.Cancelled,
        }));
        const response = await fetch(`${this.sdk.context.apiUrl}/perpsV3/orders`, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                orderUpdates: orderUpdatesBody,
            }),
        });
        const data = await response.json();
        return data;
    }
    async removeStaleConditionalOrders(accountId, chainId) {
        const response = await fetch(`${this.sdk.context.apiUrl}/perpsV3/orders/remove-stale`, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                accountId: accountId.toString(),
                networkId: chainId,
            }),
        });
        const data = await response.json();
        if (data.success)
            return;
        throw new Error(`Failed to remove orders: ${data.data.message}`);
    }
    // Contract mutations
    async depositCreditForOrders({ accountId, amount, fromMargin = false, chainId, }) {
        const amountUSDC = parseUsdcValue(amount.toString());
        const calls = [
            {
                functionName: 'creditAccountZap',
                params: [accountId, amountUSDC],
            },
        ];
        if (fromMargin) {
            const withdrawAmountWei = parseUnits(amountUSDC.toString(), 12);
            calls.unshift({
                functionName: 'modifyCollateralZap',
                params: [accountId, -withdrawAmountWei],
            });
        }
        return this.sendErc7412Transaction({
            calls,
            useMarginEngine: true,
            chainId,
        });
    }
    async withdrawCreditForOrders(accountId, amount, chainId) {
        const engine = this.contractConfigs(chainId).MarginEngineBase;
        if (!engine)
            throw new Error(UNSUPPORTED_NETWORK);
        return await this.client(chainId).simulateContract({
            ...engine,
            account: this.sdk.context.walletAddress,
            functionName: 'debitAccountZap',
            args: [accountId, amount.toBigInt(), wei(amount).mul(0.99).toBigInt()],
        });
    }
    async depositCollateral({ accountId, synthKey, amount, useMarginEngine, chainId, }) {
        return this.modifyCollateral({
            accountId,
            synthKey,
            amount,
            useMarginEngine,
            chainId,
        });
    }
    async withdrawCollateral({ accountId, synthKey, amount, useMarginEngine, chainId, }) {
        const synthId = V3_WRAPPED_TOKEN_MARKETS[chainId]?.[synthKey];
        if (synthId === undefined)
            throw new Error(UNSUPPORTED_NETWORK);
        const calls = [
            {
                functionName: 'modifyCollateral',
                params: [accountId, synthId, amount.neg().toBigInt().toString()],
            },
        ];
        const { debt, invalidReason } = await this.validateCanPayDebt(accountId, chainId);
        if (invalidReason)
            throw new Error(invalidReason);
        if (debt > 0) {
            calls.unshift({
                functionName: 'payDebt',
                params: [accountId, debt],
            });
            if (chainToV3Provider(chainId) === PerpsProvider.SNX_V3_BASE) {
                // On base we first need to zap in USDC before we can pay debt
                calls.unshift({
                    functionName: 'modifyCollateralZap',
                    params: [accountId, BigInt(formatUsdcValue(amount)), BigInt(0)],
                });
            }
        }
        return this.sendErc7412Transaction({
            calls,
            useMarginEngine,
            chainId,
        });
    }
    async depositUsdcZap({ accountId, amountWei, chainId, }) {
        const { walletAddress } = this.sdk.context;
        const contracts = this.contractConfigs(chainId);
        // Wrap and swap 1 to 1 USDC <> USDx Base only
        const engine = contracts.MarginEngineBase;
        if (!engine)
            throw new Error(UNSUPPORTED_NETWORK);
        return this.sdk.calls.erc7412Write([
            {
                from: walletAddress,
                to: engine.address,
                data: encodeFunctionData({
                    abi: engine.abi,
                    functionName: 'modifyCollateralZap',
                    args: [
                        accountId,
                        parseUsdcValue(wei(amountWei).toString()),
                        weiFromWei(amountWei).mul(0.99).toBigInt(),
                    ],
                }),
                value: ZERO_BIG_INT,
            },
        ], chainId, { useMarginEngine: 'internal' });
    }
    async depositWrappedToken({ accountId, token, amountInWei, chainId, }) {
        const { walletAddress } = this.sdk.context;
        const contracts = this.contractConfigs(chainId);
        const synthToken = DepositTokenToSynth[chainToV3Provider(chainId)][token];
        if (synthToken === SynthAssetKeysV3.USDx)
            throw new Error('Cannot wrap USDx');
        if (!synthToken)
            throw new Error(`Missing synth for token ${token}`);
        const spotMarket = V3_WRAPPED_TOKEN_MARKETS[chainId]?.[synthToken];
        const amountEthUnits = weiFromWei(amountInWei).toString();
        const amountIn = parseUnits(amountEthUnits, tokenDecimals(token, chainId));
        const tokenContract = contracts[depositableAssetToToken(token)];
        if (!tokenContract?.address)
            throw new Error(`Unsupported asset ${token}`);
        if (!spotMarket || !tokenContract)
            throw new Error(UNSUPPORTED_NETWORK);
        const engine = chainIsBase(chainId) ? contracts.MarginEngineBase : contracts.MarginEngineArb;
        const calls = [];
        if (!engine)
            throw new Error(UNSUPPORTED_NETWORK);
        // TODO: In the future we might support wrap and swap, e.g. USDC <-> USDx
        // which would require use of modifyCollateralZap
        const amountOut = wei(amountIn).mul(WRAP_SLIPPAGE_MULTIPLIER).toBigInt();
        const collateral = tokenContract.address;
        const data = token === 'ETH'
            ? encodeFunctionData({
                abi: engine.abi,
                functionName: 'depositCollateralETH',
                args: [accountId, amountIn, amountOut],
            })
            : encodeFunctionData({
                abi: engine.abi,
                functionName: 'modifyCollateralWrap',
                args: [accountId, amountIn, amountOut, collateral, BigInt(spotMarket)],
            });
        calls.push({
            from: walletAddress,
            to: engine.address,
            data: data,
            value: token === 'ETH' ? amountIn : ZERO_BIG_INT,
        });
        return this.sdk.calls.erc7412Write(calls, chainId, {
            useMarginEngine: token === 'ETH' ? 'external' : 'internal',
        });
    }
    async withdrawZapUsdc({ accountId, amountWei, chainId, }) {
        const usdcAmountIn = weiFromWei(amountWei).mul(-1).toBigInt();
        const usdcAmountOut = parseUsdcValue(weiFromWei(amountWei).mul(0.99).toString());
        return this.sendErc7412Transaction({
            calls: [
                {
                    functionName: 'modifyCollateralZap',
                    params: [accountId, usdcAmountIn, usdcAmountOut],
                },
            ],
            useMarginEngine: true,
            chainId,
        });
    }
    async withdrawUnwrapped({ accountId, token, amountWei, chainId, settleDebtWithCollateral, maxSwapSlippage, quotedSwapPrice, path, }) {
        const proxy = this.contractConfigs(chainId).PerpsV3MarketProxy;
        if (!proxy)
            throw new Error(UNSUPPORTED_NETWORK);
        const { walletAddress } = this.sdk.context;
        const { PerpsV3MarketProxy, SpotV3MarketProxy, MarginEngineArb, MarginEngineBase } = this.contractConfigs(chainId);
        const provider = chainToV3Provider(chainId);
        const synthToken = DepositTokenToSynth[provider][token];
        if (!synthToken)
            throw new Error(`Missing synth for token ${token}`);
        if (!walletAddress)
            throw new Error('Wallet address not found');
        const tokenContract = this.contractConfigs(chainId)[depositableAssetToToken(token)];
        const spotMarket = V3_WRAPPED_TOKEN_MARKETS[chainId]?.[synthToken];
        if (!SpotV3MarketProxy || !tokenContract || spotMarket === undefined || !PerpsV3MarketProxy)
            throw new Error(UNSUPPORTED_NETWORK);
        const { debt, invalidReason } = await this.validateCanPayDebt(accountId, chainId);
        if (!settleDebtWithCollateral && invalidReason)
            throw new Error(invalidReason);
        const engine = provider === PerpsProvider.SNX_V3_BASE ? MarginEngineBase : MarginEngineArb;
        if (!engine?.address)
            throw new Error(UNSUPPORTED_NETWORK);
        const engineCalls = [];
        if (debt > 0 && !settleDebtWithCollateral) {
            if (provider === PerpsProvider.SNX_V3_BASE) {
                // Need to zap in USDC before we can pay debt
                engineCalls.push({
                    from: walletAddress,
                    to: engine.address,
                    data: encodeFunctionData({
                        abi: engine.abi,
                        functionName: 'payDebtWithUSDC',
                        args: [accountId, parseUsdcValue(wei(debt).toString()), BigInt(0)],
                    }),
                    value: ZERO_BIG_INT,
                });
            }
            else {
                engineCalls.push({
                    from: walletAddress,
                    to: engine.address,
                    data: encodeFunctionData({
                        abi: engine.abi,
                        functionName: 'payDebt',
                        args: [accountId, debt],
                    }),
                    value: ZERO_BIG_INT,
                });
            }
        }
        // TODO: Get price of USDx/USDC to calculate how much USDC is required
        const unwrapAmount = wei(amountWei).mul(WRAP_SLIPPAGE_MULTIPLIER);
        let callData;
        if (token === 'ETH' && (debt === 0n || !settleDebtWithCollateral)) {
            callData = encodeFunctionData({
                abi: engine.abi,
                functionName: 'withdrawCollateralETH',
                args: [accountId, amountWei, unwrapAmount.toBigInt()],
            });
        }
        else if (debt > 0n && settleDebtWithCollateral) {
            const roundToBigInt = (value) => wei(ceilNumber(value.toNumber(), 6)).toBigInt();
            // USDC to USDx - needs to be at least the amount of debt (add some small buffer)
            const zapMinAmountWithSlippage = roundToBigInt(wei(debt));
            const debtCollateralValue = wei(debt).add(ZAP_LOAN_BUFFER).div(quotedSwapPrice).toBigInt();
            const swapAmountWithSlippage = parseUnits(wei(debtCollateralValue)
                .mul(1 + (maxSwapSlippage ?? 0.01))
                .toString(), tokenDecimals(token, chainId));
            const unwrapAmountMin = parseUnits(unwrapAmount.toString(), tokenDecimals(token, chainId));
            const amount = parseUnits(wei(amountWei).toString(), tokenDecimals(token, chainId));
            callData =
                token === 'ETH'
                    ? encodeFunctionData({
                        abi: engine.abi,
                        functionName: 'unwindCollateralETH',
                        args: [
                            accountId,
                            amount,
                            tokenContract.address,
                            zapMinAmountWithSlippage,
                            unwrapAmount.toBigInt(),
                            swapAmountWithSlippage,
                            path,
                        ],
                    })
                    : encodeFunctionData({
                        abi: engine.abi,
                        functionName: 'unwindCollateral',
                        args: [
                            accountId,
                            BigInt(spotMarket),
                            amountWei,
                            tokenContract.address,
                            zapMinAmountWithSlippage,
                            unwrapAmountMin,
                            swapAmountWithSlippage,
                            path,
                        ],
                    });
        }
        else {
            callData = encodeFunctionData({
                abi: engine.abi,
                functionName: 'modifyCollateralWrap',
                args: [
                    accountId,
                    -amountWei,
                    parseUnits(unwrapAmount.toString(), tokenDecimals(token, chainId)),
                    tokenContract.address,
                    BigInt(spotMarket),
                ],
            });
        }
        engineCalls.push({
            from: walletAddress,
            to: engine.address,
            data: callData,
            value: ZERO_BIG_INT,
        });
        const res = await this.sdk.calls.erc7412Write(engineCalls, chainId, {
            useMarginEngine: 'internal',
        });
        return res;
    }
    async quoteCollateralDebtPayment({ chainId, token, amountWei, }) {
        const tokenContract = this.contractConfigs(chainId)[token];
        const USDC = this.contractConfigs(chainId).USDC;
        const WETH = this.contractConfigs(chainId).WETH;
        if (!tokenContract)
            throw new Error(`Missing synth for token ${token}`);
        if (!USDC || !WETH)
            throw new Error(UNSUPPORTED_NETWORK);
        if (chainToV3Provider(chainId) === PerpsProvider.SNX_V3_BASE) {
            // Base version uses Odos compard to Arb version using Uniswap
            const price = this.sdk.prices.getOffchainPrice(tokenTickerToPriceAsset(token));
            // The zap contracts includes a $1 buffer plus 0.5% for slippage
            const inAmount = wei(amountWei).add(ZAP_LOAN_BUFFER).mul(1.005).div(price);
            const denominatedAmount = parseUnits(inAmount.toString(), tokenDecimals(token, chainId));
            const zapAddr = SNX_V3_PERPS_ADDRESSES.Zap[chainId];
            const quoteRequestBody = {
                chainId: chainId,
                inputTokens: [
                    {
                        tokenAddress: tokenContract.address,
                        amount: denominatedAmount.toString(), // input amount as a string in fixed integer precision
                    },
                ],
                outputTokens: [
                    {
                        tokenAddress: USDC.address,
                        proportion: 1,
                    },
                ],
                userAddr: zapAddr,
                slippageLimitPercent: 5, // (1 = 1%),
                referralCode: 0,
                disableRFQs: true,
                compact: true,
            };
            const quote = await queryOdos('quote/v2', quoteRequestBody);
            const outAmount = quote.outAmounts[0];
            if (!outAmount)
                throw new Error('Failed to get quote: no out amount returned');
            const resultingPrice = wei(quote.outAmounts[0], 12).div(wei(quote.inAmounts[0], tokenDecimals(token, chainId), true));
            const priceBefore = resultingPrice.mul(wei(quote.percentDiff).div(100).abs().add(1));
            const assembled = await queryOdos('assemble', {
                pathId: quote.pathId,
                userAddr: zapAddr,
                simulate: true,
            });
            return {
                quotedAmount: wei(inAmount),
                priceBefore: priceBefore,
                priceAfter: resultingPrice,
                priceImpact: wei(quote.priceImpact).abs().div(100),
                path: assembled.transaction.data,
            };
        }
        else {
            const poolParams = token === 'tBTC'
                ? [
                    {
                        token0: tokenContract.address,
                        token1: WETH.address,
                    },
                    {
                        token0: WETH.address,
                        token1: USDC.address,
                    },
                ]
                : [
                    {
                        token0: tokenContract.address,
                        token1: USDC.address,
                    },
                ];
            const pools = await this.getUniswapPools(chainId, poolParams);
            if (!pools.length)
                throw new Error('Failed to get Uniswap pool');
            const sqrtPrices = await this.client(chainId).multicall({
                allowFailure: false,
                contracts: pools.map((p) => ({
                    abi: UniswapPoolAbi,
                    address: p,
                    functionName: 'slot0',
                    args: [],
                })),
            });
            let priceBefore;
            if (sqrtPrices.length > 1) {
                const first = sqrtToPrice(sqrtPrices[0][0], 18, 6);
                const second = sqrtToPrice(sqrtPrices[1][0], 18, 18);
                priceBefore = wei(first).mul(second).toBigInt();
            }
            else {
                priceBefore = sqrtToPrice(sqrtPrices[0][0], 18, 6);
            }
            const usdcAmountOut = parseUsdcValue(wei(amountWei).toString());
            const path = this.getUniswapRoute(tokenContract.address, chainId);
            const quote = await this.client(chainId).simulateContract({
                abi: ZapAbi,
                address: SNX_V3_PERPS_ADDRESSES.Zap[chainId],
                functionName: 'quoteSwapFor',
                args: [path, usdcAmountOut],
            });
            let priceAfter;
            if (quote.result[1].length > 1) {
                const first = sqrtToPrice(quote.result[1][0], 18, 6);
                const second = sqrtToPrice(quote.result[1][1], 18, 18);
                priceAfter = wei(first).mul(second).toBigInt();
            }
            else {
                priceAfter = sqrtToPrice(quote.result[1][0], 18, 6);
            }
            if (!quote.result)
                throw new Error('Failed to quote swap');
            const quotedAmount = wei(quote.result[0]);
            const change = wei(priceBefore).sub(priceAfter);
            const priceImpact = change.div(priceBefore);
            return {
                quotedAmount,
                priceBefore: wei(priceBefore),
                priceAfter: wei(priceAfter),
                priceImpact,
                path,
            };
        }
    }
    async submitOrder({ marketId, accountId, sizeDelta, acceptablePrice, settlementStrategyId, referrer, chainId, }, marginEngine) {
        const depositAmountUSDC = parseUsdcValue(marginEngine?.ordersGasDeposit?.toString() ?? '0');
        const params = marginEngine?.useEngine
            ? [
                marketId,
                accountId,
                sizeDelta.toBigInt(),
                settlementStrategyId,
                acceptablePrice.toBigInt(),
                KWENTA_TRACKING_CODE,
                KWENTA_PERPS_V3_REFERRAL_ADDRESS[chainToV3Provider(chainId)],
            ]
            : [
                {
                    marketId: marketId,
                    accountId,
                    sizeDelta: sizeDelta.toBigInt().toString(),
                    settlementStrategyId,
                    referrer: referrer ?? KWENTA_PERPS_V3_REFERRAL_ADDRESS[chainToV3Provider(chainId)],
                    acceptablePrice: acceptablePrice.toBigInt().toString(),
                    trackingCode: KWENTA_TRACKING_CODE,
                },
            ];
        const calls = [];
        if (Number(depositAmountUSDC) > 0) {
            const walletBalances = await this.sdk.tokens.getBalancesAndAllowances(['USDC'], this.sdk.context.walletAddress, [], chainId);
            const walletBalance = walletBalances.USDC?.balance;
            if (wei(walletBalance ?? 0).lt(marginEngine?.ordersGasDeposit)) {
                // If the wallet balance is less than the deposit amount
                // we can withdraw it from collateral
                const withdrawAmountWei = parseUnits(depositAmountUSDC.toString(), 12);
                calls.push({
                    functionName: 'modifyCollateralZap',
                    params: [accountId, -withdrawAmountWei],
                });
            }
            calls.push({
                functionName: 'creditAccountZap',
                params: [accountId, depositAmountUSDC],
            });
        }
        calls.push({
            functionName: 'commitOrder',
            params,
        });
        return this.sendErc7412Transaction({
            calls,
            useMarginEngine: marginEngine?.useEngine,
            chainId,
        });
    }
    async executeAsyncOrder(accountId, chainId) {
        const { walletAddress } = this.sdk.context;
        const { PerpsV3MarketProxy } = this.contractConfigs(chainId);
        if (!PerpsV3MarketProxy)
            throw new Error(UNSUPPORTED_NETWORK);
        const tx = {
            to: PerpsV3MarketProxy.address,
            from: walletAddress,
            data: encodeFunctionData({
                abi: PerpsMarketV3Abi,
                functionName: 'settleOrder',
                args: [accountId],
            }),
            value: ZERO_BIG_INT,
        };
        return await this.sdk.calls.erc7412Write([tx], chainId);
    }
    async createAccount(chainId) {
        const { PerpsV3MarketProxy } = this.contractConfigs(chainId);
        if (!PerpsV3MarketProxy)
            throw new Error(UNSUPPORTED_NETWORK);
        return await this.client(chainId).simulateContract({
            ...PerpsV3MarketProxy,
            account: this.sdk.context.walletAddress,
            functionName: 'createAccount',
            args: [],
        });
    }
    async grantMarginEnginePermission(accountId, chainId) {
        const { PerpsV3MarketProxy, MarginEngineArb, MarginEngineBase } = this.contractConfigs(chainId);
        const marginEngine = chainToV3Provider(chainId) === PerpsProvider.SNX_V3_BASE ? MarginEngineBase : MarginEngineArb;
        if (!PerpsV3MarketProxy || !marginEngine)
            throw new Error(UNSUPPORTED_NETWORK);
        return await this.client(chainId).simulateContract({
            ...PerpsV3MarketProxy,
            account: this.sdk.context.walletAddress,
            functionName: 'grantPermission',
            args: [accountId, stringToHex('ADMIN', { size: 32 }), marginEngine.address],
        });
    }
    async grantDelegatePermission(accountId, delegate, chainId) {
        const { PerpsV3MarketProxy } = this.contractConfigs(chainId);
        if (!PerpsV3MarketProxy)
            throw new Error(UNSUPPORTED_NETWORK);
        return await this.client(chainId).simulateContract({
            ...PerpsV3MarketProxy,
            account: this.sdk.context.walletAddress,
            functionName: 'grantPermission',
            args: [accountId, stringToHex('PERPS_COMMIT_ASYNC_ORDER', { size: 32 }), delegate],
        });
    }
    async revokeDelegatePermission(accountId, delegate, chainId) {
        const { PerpsV3MarketProxy } = this.contractConfigs(chainId);
        if (!PerpsV3MarketProxy)
            throw new Error(UNSUPPORTED_NETWORK);
        return await this.client(chainId).simulateContract({
            ...PerpsV3MarketProxy,
            account: this.sdk.context.walletAddress,
            functionName: 'revokePermission',
            args: [accountId, stringToHex('PERPS_COMMIT_ASYNC_ORDER', { size: 32 }), delegate],
        });
    }
    async checkDelegatePermission(accountId, delegate, chainId) {
        const { PerpsV3MarketProxy } = this.contractConfigs(chainId);
        if (!PerpsV3MarketProxy)
            throw new Error(UNSUPPORTED_NETWORK);
        const result = await this.client(chainId).readContract({
            ...PerpsV3MarketProxy,
            account: this.sdk.context.walletAddress,
            functionName: 'hasPermission',
            args: [accountId, stringToHex('PERPS_COMMIT_ASYNC_ORDER', { size: 32 }), delegate],
        });
        return result;
    }
    async getDelegatesForAccount(walletAddress, chainId) {
        return await queryDelegatesForAccount(chainId, walletAddress);
    }
    async getSubAccountsForAccount(walletAddress, chainId) {
        return await querySubAccountsForAccount(chainId, walletAddress);
    }
    // private helpers
    inputToConditionalOrder(input, nonce) {
        const orderDetails = {
            marketId: input.marketId,
            networkId: input.networkId,
            accountId: input.accountId.toString(),
            sizeDelta: input.sizeDelta,
            settlementStrategyId: input.settlementStrategyId,
            acceptablePrice: input.acceptablePrice,
            isReduceOnly: input.isReduceOnly,
            trackingCode: KWENTA_TRACKING_CODE,
            referrer: KWENTA_PERPS_V3_REFERRAL_ADDRESS[chainToV3Provider(input.networkId)],
        };
        const conditions = orderConditionsToCallData(input, input.rawConditions);
        const trustedExecutor = ORDERS_KEEPER_ADDRESSES[Number(input.networkId)];
        return {
            orderDetails,
            signer: this.sdk.context.walletAddress,
            nonce: nonce,
            requireVerified: false,
            trustedExecutor,
            maxExecutorFee: input.maxExecutorFee,
            conditions: conditions,
        };
    }
    async signConditionalOrder(conditionalOrder, chainId) {
        const { MarginEngineArb, MarginEngineBase } = this.contractConfigs(chainId);
        const engine = chainToV3Provider(chainId) === PerpsProvider.SNX_V3_BASE ? MarginEngineBase : MarginEngineArb;
        if (!engine) {
            throw new Error(UNSUPPORTED_NETWORK);
        }
        await this.sdk.context.walletClient.switchChain({ id: chainId });
        const signature = (await this.sdk.transactions.signTypedData({
            account: this.sdk.context.walletAddress,
            domain: {
                name: 'SMv3: OrderBook',
                version: '1',
                chainId,
                verifyingContract: engine.address,
            },
            primaryType: 'ConditionalOrder',
            types: {
                OrderDetails: [
                    { name: 'marketId', type: 'uint128' },
                    { name: 'accountId', type: 'uint128' },
                    { name: 'sizeDelta', type: 'int128' },
                    { name: 'settlementStrategyId', type: 'uint128' },
                    { name: 'acceptablePrice', type: 'uint256' },
                    { name: 'isReduceOnly', type: 'bool' },
                    { name: 'trackingCode', type: 'bytes32' },
                    { name: 'referrer', type: 'address' },
                ],
                ConditionalOrder: [
                    { name: 'orderDetails', type: 'OrderDetails' },
                    { name: 'signer', type: 'address' },
                    { name: 'nonce', type: 'uint256' },
                    { name: 'requireVerified', type: 'bool' },
                    { name: 'trustedExecutor', type: 'address' },
                    { name: 'maxExecutorFee', type: 'uint256' },
                    { name: 'conditions', type: 'bytes[]' },
                ],
            },
            message: this.formatOrderForSigning(conditionalOrder),
        }, chainId));
        return signature;
    }
    formatOrderForSigning(order) {
        return {
            orderDetails: {
                marketId: BigInt(order.orderDetails.marketId),
                accountId: BigInt(order.orderDetails.accountId),
                sizeDelta: order.orderDetails.sizeDelta,
                settlementStrategyId: BigInt(order.orderDetails.settlementStrategyId),
                acceptablePrice: order.orderDetails.acceptablePrice,
                isReduceOnly: order.orderDetails.isReduceOnly,
                trackingCode: order.orderDetails.trackingCode,
                referrer: order.orderDetails.referrer,
            },
            signer: order.signer,
            nonce: BigInt(order.nonce),
            requireVerified: order.requireVerified,
            trustedExecutor: order.trustedExecutor,
            maxExecutorFee: order.maxExecutorFee,
            conditions: order.conditions,
        };
    }
    async getNonce(accountId) {
        const response = await fetch(`${this.sdk.context.apiUrl}/perpsV3/account/${accountId.toString()}/nonce`);
        const { data } = await response.json();
        return data.nonce;
    }
    modifyCollateral({ accountId, synthKey, amount, useMarginEngine, chainId, }) {
        const synthId = V3_WRAPPED_TOKEN_MARKETS[chainId][synthKey];
        const engine = chainToV3Provider(chainId) === PerpsProvider.SNX_V3_BASE
            ? 'MarginEngineBase'
            : 'MarginEngineArb';
        const proxy = useMarginEngine
            ? this.contractConfigs(chainId)[engine]
            : this.contractConfigs(chainId).PerpsV3MarketProxy;
        if (synthId === undefined)
            throw new Error(`No contract found for Synth ${synthKey}`);
        if (!proxy)
            throw new Error(UNSUPPORTED_NETWORK);
        return this.sendErc7412Transaction({
            calls: [
                {
                    functionName: 'modifyCollateral',
                    params: [accountId, synthId, amount.toBigInt().toString()],
                },
            ],
            useMarginEngine,
            chainId,
        });
    }
    async getCollateralTransfers(chainId, accountId) {
        const response = await queryCollateralChanges(chainId, accountId?.toString());
        return response ? mapCollateralChanges(response.collateralChanges, chainId) : [];
    }
    async getOrderHistory(accountId, chainId, options) {
        const conditionalOrders = await this.getConditionalOrders(accountId, chainId);
        const marketOrdersResponse = await queryOrderSettleds(chainId, accountId?.toString(), options);
        return reconcileOrders(chainId, marketOrdersResponse.orderSettleds ?? [], conditionalOrders);
    }
    async getMaxDepositAmounts(tokens, chainId) {
        const items = tokens.reduce((acc, t) => {
            const synth = DepositTokenToSynth[chainToV3Provider(chainId)][t];
            if (!synth)
                return acc;
            const spotMarket = V3_WRAPPED_TOKEN_MARKETS[chainId]?.[synth];
            const tokenContract = this.contractConfigs(chainId)[depositableAssetToToken(t)];
            if (synth && tokenContract && spotMarket !== undefined)
                acc.push({
                    synth,
                    token: t,
                    spotMarket,
                    tokenAddress: tokenContract.address,
                });
            return acc;
        }, []);
        const { SpotV3MarketProxy, CoreProxy } = this.contractConfigs(chainId);
        if (!SpotV3MarketProxy || !CoreProxy || !items.length)
            throw new Error(UNSUPPORTED_NETWORK);
        const wrapperCalls = items.map((item) => ({
            ...SpotV3MarketProxy,
            functionName: 'getWrapper',
            args: [BigInt(item.spotMarket)],
        }));
        const collateralCalls = items.map((item) => ({
            ...CoreProxy,
            functionName: 'getMarketCollateralAmount',
            args: [BigInt(item.spotMarket), item.tokenAddress],
        }));
        const results = await this.client(chainId).multicall({
            allowFailure: false,
            contracts: [...wrapperCalls, ...collateralCalls],
        });
        const wrapperResults = results.slice(0, items.length);
        const collateralResults = results.slice(items.length);
        const maxWrappableAmounts = wrapperResults.reduce((acc, r, i) => {
            // @ts-ignore inferred incorrectly
            const maxWrappableAmount = r[1] ?? 0;
            const marketCollateralAmount = collateralResults[i] ?? 0;
            acc[items[i].token] = wei(maxWrappableAmount).sub(wei(marketCollateralAmount)).toString();
            return acc;
        }, {});
        return maxWrappableAmounts;
    }
    prepareEthToWeth(amountWei, chainId) {
        return {
            from: this.sdk.context.walletAddress,
            to: TOKEN_ADDRESSES.WETH[chainId],
            data: encodeFunctionData({
                abi: WethAbi,
                functionName: 'depositTo',
                args: [this.sdk.context.walletAddress],
            }),
            value: amountWei,
        };
    }
    async validateCanPayDebt(accountId, chainId) {
        const { PerpsV3MarketProxy, MarginEngineBase } = this.contractConfigs(chainId);
        const debt = await this.client(chainId).readContract({
            ...PerpsV3MarketProxy,
            functionName: 'debt',
            args: [accountId],
        });
        if (chainToV3Provider(chainId) === PerpsProvider.SNX_V3_BASE && !MarginEngineBase)
            throw new Error(UNSUPPORTED_NETWORK);
        const address = chainToV3Provider(chainId) === PerpsProvider.SNX_V3_BASE
            ? MarginEngineBase.address
            : PerpsV3MarketProxy.address;
        const baseToken = chainToV3Provider(chainId) === PerpsProvider.SNX_V3_BASE
            ? DepositableAssetKeysV3.USDC
            : DepositableAssetKeysV3.USDx;
        const walletBalances = await this.sdk.tokens.getBalancesAndAllowances([baseToken], this.sdk.context.walletAddress, [address], chainId);
        const allowance = walletBalances[baseToken]?.allowances[address];
        const balance = walletBalances[baseToken]?.balance;
        if (wei(balance ?? 0).lt(weiFromWei(debt)))
            return { debt: debt, invalidReason: `Insufficient ${baseToken} balance to pay debt` };
        if (wei(allowance ?? 0).lt(weiFromWei(debt)))
            return { debt: debt, invalidReason: `Insufficient ${baseToken} allowance for debt` };
        return { debt };
    }
    async getUniswapPools(chainId, pools) {
        const uniswapFactoryAddress = COMMON_ADDRESSES.UniswapFactory[chainId];
        if (!uniswapFactoryAddress)
            throw new Error(UNSUPPORTED_NETWORK);
        const cachedPools = pools.map((p) => this.uniswapPools[chainId]?.[p.token0 + p.token1]);
        if (cachedPools.filter(notNill).length === pools.length)
            return cachedPools;
        const res = (await this.client(chainId).multicall({
            allowFailure: false,
            contracts: pools.map((p) => {
                return {
                    abi: UniswapFactoryAbi,
                    address: uniswapFactoryAddress,
                    functionName: 'getPool',
                    args: [p.token0, p.token1, UNISWAP_POOL_FEES[chainId][p.token0]],
                };
            }),
        }));
        res.forEach((r, i) => {
            this.uniswapPools[chainId][pools[i].token0 + pools[i].token1] = r;
        });
        return res;
    }
    getUniswapRoute(tokenInAddress, chainId) {
        const usdcAddress = TOKEN_ADDRESSES[DepositableAssetKeysV3.USDC][chainId];
        const wethAddress = TOKEN_ADDRESSES[DepositableAssetKeysV3.WETH][chainId];
        const tbtcAddress = TOKEN_ADDRESSES[DepositableAssetKeysV3.tBTC][chainId];
        const usdeAddress = TOKEN_ADDRESSES[DepositableAssetKeysV3.USDe][chainId];
        const fees = UNISWAP_POOL_FEES[chainId];
        if (!usdcAddress || !wethAddress || !tbtcAddress)
            throw new Error(UNSUPPORTED_NETWORK);
        if (tokenInAddress === tbtcAddress)
            return encodePacked(['address', 'uint24', 'address', 'uint24', 'address'], [usdcAddress, fees[wethAddress], wethAddress, fees[tbtcAddress], tbtcAddress]);
        if (tokenInAddress === usdeAddress)
            return encodePacked(['address', 'uint24', 'address'], [usdcAddress, fees[usdeAddress], usdeAddress]);
        if (tokenInAddress === wethAddress)
            return encodePacked(['address', 'uint24', 'address'], [usdcAddress, fees[wethAddress], wethAddress]);
        throw new Error(`Missing swap path for token ${tokenInAddress}`);
    }
    getCollateralUpdateOracleIds(assets) {
        const possibleCollaterals = [
            DepositableAssetKeysV3.USDe,
            DepositableAssetKeysV3.USDC,
            DepositableAssetKeysV3.tBTC,
            FuturesMarketAsset.sETH,
            FuturesMarketAsset.sBTC,
        ];
        return [
            ...new Set([...assets, ...possibleCollaterals].map((a) => PYTH_IDS_BY_ASSET[a]).filter(notNill)),
        ];
    }
}
