import request, { gql } from 'graphql-request';
import { ADDRESSES_PER_LOOKUP, DEFAULT_LEADERBOARD_DATA, ENS_REVERSE_LOOKUP, } from '../constants/stats';
import { ETH_UNIT } from '../constants/transactions';
import { formatPerennialLeaderboardResponse, formatSnxLeaderboardResponse, mapAndSortStats, mapStat, mapStatRank, } from '../utils/stats';
import { truncateAddress } from '../utils/string';
import chunk from 'lodash/chunk';
import { parseAbi } from 'viem';
import { getFuturesStats } from '../queries/futures';
import { PerennialArbNetworkIds, SnxV2NetworkIds, } from '../types';
import { getPerennialSubgraphUrl, getPerpsV2SubgraphUrl, getPerpsV3SubgraphUrl } from '../utils';
export default class StatsService {
    constructor(sdk) {
        this.sdk = sdk;
    }
    async getStatsVolumes() { }
    async getFuturesTradersStats() { }
    async getFuturesStats(chainId) {
        try {
            const response = await getFuturesStats(getPerpsV2SubgraphUrl(chainId), {
                first: 10,
                orderBy: 'pnlWithFeesPaid',
                orderDirection: 'desc',
            });
            const stats = response.map((stat, i) => ({
                trader: stat.account,
                traderShort: truncateAddress(stat.account),
                pnl: stat.pnlWithFeesPaid.div(ETH_UNIT).toString(),
                totalVolume: stat.totalVolume.div(ETH_UNIT).toString(),
                totalTrades: stat.totalTrades.toNumber(),
                liquidations: stat.liquidations.toNumber(),
                rank: i + 1,
                rankText: (i + 1).toString(),
            }));
            return stats;
        }
        catch (e) {
            this.sdk.context.logError(e);
            return [];
        }
    }
    async getSnxV2Leaderboard(searchTerm, account, first = 10000, skip = 0) {
        try {
            const query = gql `
				fragment StatsBody on FuturesStat {
					accountOwner: account
					pnl
					pnlWithFeesPaid
					liquidations
					totalTrades
					totalVolume
				}

				query leaderboardStats($account: String!, $searchTerm: String!, $first: Int!, $skip: Int!) {
					top: futuresStats(orderBy: pnlWithFeesPaid, orderDirection: desc) {
						...StatsBody
					}
					wallet: futuresStats(where: { account: $account }) {
						...StatsBody
					}
					search: futuresStats(where: { account_contains: $searchTerm }) {
						...StatsBody
					}
				}
			`;
            const response = await request(getPerpsV2SubgraphUrl(SnxV2NetworkIds.OPTIMISM_MAINNET), query, { account, searchTerm, first, skip });
            const formattedResponse = formatSnxLeaderboardResponse(response);
            // TODO: Improve the time complexity of this operation.
            // We *should* be able to add the ENS and merge at the same time.
            const ensInfo = await this.batchGetENS(Object.values(formattedResponse)
                .flat(1)
                .map(({ accountOwner }) => accountOwner));
            const statTransform = mapStat(ensInfo);
            const stats = {
                all: mapAndSortStats([
                    ...formattedResponse.top,
                    ...formattedResponse.wallet,
                    ...formattedResponse.search,
                ]).map(statTransform),
                wallet: formattedResponse.wallet.map(statTransform),
                search: searchTerm && searchTerm !== '' ? formattedResponse.search.map(statTransform) : [],
            };
            return { ...stats };
        }
        catch (e) {
            this.sdk.context.logError(e);
            return DEFAULT_LEADERBOARD_DATA;
        }
    }
    async getPerennialV2Leaderboard(searchTerm, account, first = 10000, skip = 0) {
        try {
            const query = gql `
				fragment StatsBody on AccountAccumulation {
					accountOwner: account {
						id
					}
					pnlWithFeesPaid: accumulation {
						metadata_net
					}
					liquidations
					totalTrades: trades
					takerNotional
					makerNotional
				}

				query AccountAccumulation(
					$account: String!
					$searchTerm: String!
					$first: Int!
					$skip: Int!
				) {
					top: accountAccumulations(
						orderBy: takerAccumulation__metadata_net
						orderDirection: desc
						where: { bucket: all }
					) {
						...StatsBody
					}
					wallet: accountAccumulations(where: { account_: { id: $account }, bucket: all }) {
						...StatsBody
					}
					search: accountAccumulations(
						where: { account_: { id_contains: $searchTerm }, bucket: all }
					) {
						...StatsBody
					}
				}
			`;
            const response = await request(getPerennialSubgraphUrl(PerennialArbNetworkIds.ARB_MAINNET), query, { account, searchTerm, first, skip });
            const formattedResponse = formatPerennialLeaderboardResponse(response);
            const ensInfo = await this.batchGetENS(Object.values(formattedResponse)
                .flat(1)
                .map(({ accountOwner }) => accountOwner));
            const statTransform = mapStat(ensInfo);
            const stats = {
                all: mapAndSortStats([
                    ...formattedResponse.top,
                    ...formattedResponse.wallet,
                    ...formattedResponse.search,
                ]).map(statTransform),
                wallet: formattedResponse.wallet.map(statTransform),
                search: searchTerm && searchTerm !== '' ? formattedResponse.search.map(statTransform) : [],
            };
            return { ...stats };
        }
        catch (e) {
            this.sdk.context.logError(e);
            return DEFAULT_LEADERBOARD_DATA;
        }
    }
    async getV3Leaderboard(networkId, searchTerm, account, first = 10000, skip = 0) {
        try {
            const query = gql `
				fragment StatsBody on PerpsV3Stat {
					accountId
					accountOwner
					pnl
					pnlWithFeesPaid
					liquidations
					totalTrades
					totalVolume
				}

				query leaderboardStats($account: String!, $searchTerm: String!, $first: Int!, $skip: Int!) {
					top: perpsV3Stats(
						first: $first
						skip: $skip
						orderBy: pnlWithFeesPaid
						orderDirection: desc
					) {
						...StatsBody
					}
					wallet: perpsV3Stats(where: { accountOwner: $account }) {
						...StatsBody
					}
					search: perpsV3Stats(where: { accountOwner_contains: $searchTerm }) {
						...StatsBody
					}
				}
			`;
            const response = await request(getPerpsV3SubgraphUrl(networkId), query, { account, searchTerm, first, skip });
            const formattedResponse = formatSnxLeaderboardResponse(response);
            const ensInfo = await this.batchGetENS(Object.values(formattedResponse)
                .flat(1)
                .map(({ accountOwner }) => accountOwner));
            const statTransform = mapStat(ensInfo);
            const allStats = mapAndSortStats([
                ...formattedResponse.top,
                ...formattedResponse.wallet,
                ...formattedResponse.search,
            ]);
            const statRankTransform = mapStatRank(allStats);
            const stats = {
                all: allStats.map(statTransform),
                wallet: formattedResponse.wallet.map(statTransform).map(statRankTransform),
                search: searchTerm && searchTerm !== '' ? formattedResponse.search.map(statTransform) : [],
            };
            return { ...stats };
        }
        catch (e) {
            this.sdk.context.logError(e);
            return DEFAULT_LEADERBOARD_DATA;
        }
    }
    async batchGetENS(addresses) {
        const config = {
            address: ENS_REVERSE_LOOKUP,
            abi: parseAbi(['function getNames(address[] addresses) external view returns (string[] r)']),
            functionName: 'getNames',
        };
        const addressesChunks = chunk(addresses, ADDRESSES_PER_LOOKUP);
        const ensResults = await this.sdk.context.l1MainnetProvider.multicall({
            allowFailure: true,
            contracts: addressesChunks.map((addresses) => ({
                ...config,
                args: [addresses],
            })),
        });
        const ensInfo = {};
        ensResults.forEach((result, i) => {
            const addresses = addressesChunks[i];
            if (result.status === 'success') {
                result.result.forEach((name, j) => {
                    if (name !== '') {
                        ensInfo[addresses[j]] = name;
                    }
                });
            }
        });
        return ensInfo;
    }
}
