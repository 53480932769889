import type { PricesMap } from '@kwenta/sdk/types'
import { wei } from '@kwenta/wei'

import type { PricesInfoMap } from 'state/prices/types'

export const getPricesInfo = (oldPrices: PricesInfoMap, newPrices: PricesMap<string>) => {
	const pricesInfo: PricesInfoMap = {}

	let asset: keyof PricesMap<string>
	for (asset in newPrices) {
		const newPrice = wei(newPrices[asset] ?? 0)
		const oldPrice = oldPrices[asset]?.price ? wei(oldPrices[asset]!.price) : null
		const oldChange = oldPrices[asset]?.change

		pricesInfo[asset] = {
			price: newPrice.toString(),
			change: oldPrice
				? newPrice.gt(oldPrice)
					? 'up'
					: oldPrice.gt(newPrice)
						? 'down'
						: oldChange ?? null
				: null,
		}
	}
	return pricesInfo
}
