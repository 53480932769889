// TODO: Make this dynamic
import { wei } from '@kwenta/wei';
import { TOKEN_ADDRESSES } from '../contracts/constants';
import { FuturesMarketAsset, FuturesMarketKey, PerpsProvider, SnxV3NetworkIds, SupportedNetworkIds, } from '../types';
import { MarketAssetByKey, MarketKeyByAsset, chainToV3Provider, inverseObj } from '../utils';
import { COLLATERAL_PYTH_IDS, PERPS_PYTH_IDS, SynthAssetKeysV3, V3_WRAPPED_TOKEN_MARKETS, } from './futures';
export const V3_PERPS_MARKET_IDS = {
    [PerpsProvider.SNX_V3_BASE]: {
        [FuturesMarketKey.sETHPERP]: '100',
        [FuturesMarketKey.sBTCPERP]: '200',
        [FuturesMarketKey.sSNXPERP]: '300',
        [FuturesMarketKey.sSOLPERP]: '400',
        [FuturesMarketKey.sWIFPERP]: '500',
        [FuturesMarketKey.sWPERP]: '600',
        [FuturesMarketKey.sENAPERP]: '700',
        [FuturesMarketKey.sDOGEPERP]: '800',
        [FuturesMarketKey.sAVAXPERP]: '900',
        [FuturesMarketKey.sOPPERP]: '1000',
        [FuturesMarketKey.sORDIPERP]: '1100',
        [FuturesMarketKey.sPEPEPERP]: '1200',
        [FuturesMarketKey.sRUNEPERP]: '1300',
        [FuturesMarketKey.sBONKPERP]: '1400',
        [FuturesMarketKey.sFTMPERP]: '1500',
        [FuturesMarketKey.sARBPERP]: '1600',
        [FuturesMarketKey.sPOLPERP]: '1700',
        [FuturesMarketKey.sBNBPERP]: '1800',
        [FuturesMarketKey.sLINKPERP]: '1900',
        [FuturesMarketKey.sPENDLEPERP]: '2000',
        [FuturesMarketKey.sINJPERP]: '2100',
        [FuturesMarketKey.sGMXPERP]: '2200',
        [FuturesMarketKey.sTIAPERP]: '2300',
        [FuturesMarketKey.sSUIPERP]: '2400',
        [FuturesMarketKey.sTONPERP]: '2500',
        [FuturesMarketKey.sARKMPERP]: '2600',
        [FuturesMarketKey.sGALAPERP]: '2700',
        [FuturesMarketKey.sTAOPERP]: '2800',
        [FuturesMarketKey.sBOMEPERP]: '2900',
        [FuturesMarketKey.sETHFIPERP]: '3000',
        [FuturesMarketKey.sSTXPERP]: '3100',
        [FuturesMarketKey.sAXLPERP]: '3200',
        [FuturesMarketKey.sAAVEPERP]: '3300',
        [FuturesMarketKey.sADAPERP]: '3400',
        [FuturesMarketKey.sALGOPERP]: '3500',
        [FuturesMarketKey.sAPTPERP]: '3600',
        [FuturesMarketKey.sATOMPERP]: '3700',
        [FuturesMarketKey.sAXSPERP]: '3800',
        [FuturesMarketKey.sBALPERP]: '3900',
        [FuturesMarketKey.sBCHPERP]: '4000',
        [FuturesMarketKey.sBLURPERP]: '4100',
        [FuturesMarketKey.sCOMPPERP]: '4200',
        [FuturesMarketKey.sCRVPERP]: '4300',
        [FuturesMarketKey.sDOTPERP]: '4400',
        [FuturesMarketKey.sDYDXPERP]: '4500',
        [FuturesMarketKey.sEOSPERP]: '4600',
        [FuturesMarketKey.sETCPERP]: '4700',
        [FuturesMarketKey.sETHBTCPERP]: '4800',
        [FuturesMarketKey.sFILPERP]: '4900',
        [FuturesMarketKey.sFLOWPERP]: '5000',
        [FuturesMarketKey.sFXSPERP]: '5100',
        [FuturesMarketKey.sGRTPERP]: '5200',
        [FuturesMarketKey.sICPPERP]: '5300',
        [FuturesMarketKey.sIMXPERP]: '5400',
        [FuturesMarketKey.sJTOPERP]: '5500',
        [FuturesMarketKey.sJUPPERP]: '5600',
        [FuturesMarketKey.sLDOPERP]: '5700',
        [FuturesMarketKey.sLTCPERP]: '5800',
        [FuturesMarketKey.sMEMEPERP]: '6000',
        [FuturesMarketKey.sNEARPERP]: '6100',
        [FuturesMarketKey.sPYTHPERP]: '6200',
        [FuturesMarketKey.sRNDRPERP]: '6300',
        [FuturesMarketKey.sSEIPERP]: '6400',
        [FuturesMarketKey.sSHIBPERP]: '6500',
        [FuturesMarketKey.sSTRKPERP]: '6600',
        [FuturesMarketKey.sSUSHIPERP]: '6700',
        [FuturesMarketKey.sTRXPERP]: '6800',
        [FuturesMarketKey.sUNIPERP]: '6900',
        [FuturesMarketKey.sXLMPERP]: '7000',
        [FuturesMarketKey.sXRPPERP]: '7100',
        [FuturesMarketKey.sYFIPERP]: '7200',
        [FuturesMarketKey.sEIGENPERP]: '7300',
        [FuturesMarketKey.sIOPERP]: '7400',
        [FuturesMarketKey.sMEWPERP]: '7500',
        [FuturesMarketKey.sMKRPERP]: '7600',
        [FuturesMarketKey.sNOTPERP]: '7700',
        [FuturesMarketKey.sPEOPLEPERP]: '7800',
        [FuturesMarketKey.sPOPCATPERP]: '8000',
        [FuturesMarketKey.sRENDERPERP]: '8100',
        [FuturesMarketKey.sSATSPERP]: '8200',
        [FuturesMarketKey.sWLDPERP]: '8300',
        [FuturesMarketKey.sZROPERP]: '8400',
        [FuturesMarketKey.sSAFEPERP]: '8500',
        [FuturesMarketKey.sGOATPERP]: '8600',
        [FuturesMarketKey.sMOODENGPERP]: '8700',
        [FuturesMarketKey.sPNUTPERP]: '8800',
        [FuturesMarketKey.sCATPERP]: '8900',
        [FuturesMarketKey.sDEGENPERP]: '9000',
        [FuturesMarketKey.sSLERFPERP]: '9100',
    },
    [PerpsProvider.SNX_V3_ARB]: {
        [FuturesMarketKey.sETHPERP]: '100',
        [FuturesMarketKey.sBTCPERP]: '200',
        [FuturesMarketKey.sSOLPERP]: '300',
        [FuturesMarketKey.sWIFPERP]: '400',
        [FuturesMarketKey.sLINKPERP]: '500',
        [FuturesMarketKey.sARBPERP]: '600',
        [FuturesMarketKey.sDOGEPERP]: '700',
        [FuturesMarketKey.sGMXPERP]: '800',
        [FuturesMarketKey.sAAVEPERP]: '900',
        [FuturesMarketKey.sNEARPERP]: '1000',
        [FuturesMarketKey.sUNIPERP]: '1100',
        [FuturesMarketKey.sXRPPERP]: '1200',
        [FuturesMarketKey.sLTCPERP]: '1300',
        [FuturesMarketKey.sORDIPERP]: '1400',
        [FuturesMarketKey.sSTXPERP]: '1500',
        [FuturesMarketKey.sSHIBPERP]: '1600',
        [FuturesMarketKey.sBNBPERP]: '1700',
        [FuturesMarketKey.sAVAXPERP]: '1800',
        [FuturesMarketKey.sPEPEPERP]: '1900',
        [FuturesMarketKey.sOPPERP]: '2000',
        [FuturesMarketKey.sRUNEPERP]: '2100',
        [FuturesMarketKey.sPYTHPERP]: '2200',
        [FuturesMarketKey.sCRVPERP]: '2300',
        [FuturesMarketKey.sTIAPERP]: '2400',
        [FuturesMarketKey.sADAPERP]: '2500',
        [FuturesMarketKey.sBCHPERP]: '2600',
        [FuturesMarketKey.sDYDXPERP]: '2700',
        [FuturesMarketKey.sPOLPERP]: '2800',
        [FuturesMarketKey.sMKRPERP]: '2900',
        [FuturesMarketKey.sXLMPERP]: '3000',
        [FuturesMarketKey.sALGOPERP]: '3100',
        [FuturesMarketKey.sAPTPERP]: '3200',
        [FuturesMarketKey.sARKMPERP]: '3300',
        [FuturesMarketKey.sATOMPERP]: '3400',
        [FuturesMarketKey.sAXLPERP]: '3500',
        [FuturesMarketKey.sAXSPERP]: '3600',
        [FuturesMarketKey.sBALPERP]: '3700',
        [FuturesMarketKey.sBLURPERP]: '3800',
        [FuturesMarketKey.sBOMEPERP]: '3900',
        [FuturesMarketKey.sBONKPERP]: '4000',
        [FuturesMarketKey.sCOMPPERP]: '4100',
        [FuturesMarketKey.sDOTPERP]: '4200',
        [FuturesMarketKey.sENAPERP]: '4300',
        [FuturesMarketKey.sEOSPERP]: '4400',
        [FuturesMarketKey.sETCPERP]: '4500',
        [FuturesMarketKey.sETHBTCPERP]: '4600',
        [FuturesMarketKey.sETHFIPERP]: '4700',
        [FuturesMarketKey.sFILPERP]: '4800',
        [FuturesMarketKey.sFLOWPERP]: '4900',
        [FuturesMarketKey.sFTMPERP]: '5000',
        [FuturesMarketKey.sFXSPERP]: '5100',
        [FuturesMarketKey.sGALAPERP]: '5200',
        [FuturesMarketKey.sGRTPERP]: '5300',
        [FuturesMarketKey.sICPPERP]: '5400',
        [FuturesMarketKey.sIMXPERP]: '5500',
        [FuturesMarketKey.sINJPERP]: '5600',
        [FuturesMarketKey.sJTOPERP]: '5700',
        [FuturesMarketKey.sJUPPERP]: '5800',
        [FuturesMarketKey.sLDOPERP]: '5900',
        [FuturesMarketKey.sMEMEPERP]: '6000',
        [FuturesMarketKey.sPENDLEPERP]: '6100',
        [FuturesMarketKey.sSEIPERP]: '6200',
        [FuturesMarketKey.sSNXPERP]: '6300',
        [FuturesMarketKey.sSTRKPERP]: '6400',
        [FuturesMarketKey.sSUIPERP]: '6500',
        [FuturesMarketKey.sSUSHIPERP]: '6600',
        [FuturesMarketKey.sTAOPERP]: '6700',
        [FuturesMarketKey.sTONPERP]: '6800',
        [FuturesMarketKey.sTRXPERP]: '6900',
        [FuturesMarketKey.sWPERP]: '7000',
        [FuturesMarketKey.sYFIPERP]: '7100',
        [FuturesMarketKey.sMEWPERP]: '7200',
        [FuturesMarketKey.sPOPCATPERP]: '7300',
        [FuturesMarketKey.sEIGENPERP]: '7400',
        [FuturesMarketKey.sWLDPERP]: '7500',
        [FuturesMarketKey.sPEOPLEPERP]: '7600',
        [FuturesMarketKey.sZROPERP]: '7700',
        [FuturesMarketKey.sRENDERPERP]: '7800',
        [FuturesMarketKey.sNOTPERP]: '7900',
        [FuturesMarketKey.sSATSPERP]: '8000',
        [FuturesMarketKey.sIOPERP]: '8100',
        [FuturesMarketKey.sSAFEPERP]: '8500',
        [FuturesMarketKey.sGOATPERP]: '8600',
        [FuturesMarketKey.sMOODENGPERP]: '8700',
        [FuturesMarketKey.sPNUTPERP]: '8800',
        [FuturesMarketKey.sCATPERP]: '8900',
        [FuturesMarketKey.sDEGENPERP]: '9000',
        [FuturesMarketKey.sSLERFPERP]: '9100',
    },
};
export const V3_SYNTH_KEYS_BASE = Object.keys(V3_WRAPPED_TOKEN_MARKETS[SupportedNetworkIds.BASE_MAINNET]);
export const V3_SYNTH_KEYS_ARB = Object.keys(V3_WRAPPED_TOKEN_MARKETS[SupportedNetworkIds.ARB_MAINNET]);
export const V3_SYNTH_KEYS = [...new Set([...V3_SYNTH_KEYS_BASE, ...V3_SYNTH_KEYS_ARB])];
export const V3_PERPS_MARKET_IDS_TO_KEYS = {
    [PerpsProvider.SNX_V3_BASE]: inverseObj(V3_PERPS_MARKET_IDS[PerpsProvider.SNX_V3_BASE]),
    [PerpsProvider.SNX_V3_ARB]: inverseObj(V3_PERPS_MARKET_IDS[PerpsProvider.SNX_V3_ARB]),
};
export const v3PerpsMarketIdToAssetKey = (chainId, marketId) => {
    const key = V3_PERPS_MARKET_IDS_TO_KEYS[chainToV3Provider(chainId)][marketId];
    return MarketAssetByKey[key];
};
export const v3MarketIdByMarketAsset = (chainId, asset) => {
    const provider = chainToV3Provider(chainId);
    if (provider === PerpsProvider.SNX_V3_ARB) {
        const key = MarketKeyByAsset[asset];
        return V3_PERPS_MARKET_IDS[PerpsProvider.SNX_V3_ARB][key];
    }
    else {
        const key = MarketKeyByAsset[asset];
        return V3_PERPS_MARKET_IDS[PerpsProvider.SNX_V3_BASE][key];
    }
};
export const v3SynthIdToKey = (id, chainId) => {
    const record = V3_WRAPPED_TOKEN_MARKETS[chainId];
    const mapping = inverseObj(record);
    if (chainId === SnxV3NetworkIds.BASE_MAINNET || chainId === SnxV3NetworkIds.BASE_SEPOLIA) {
        mapping[0] = SynthAssetKeysV3.sUSDC;
    }
    return mapping[id];
};
export const assetToTokenTicker = (asset) => {
    if (asset === 'sBTC')
        return 'tBTC';
    if (asset.startsWith('s'))
        return asset.slice(1);
    return asset;
};
export const tokenTickerToPriceAsset = (token) => {
    switch (token) {
        case 'sETH':
        case 'WETH':
        case 'ETH':
            return FuturesMarketAsset.sETH;
        case 'tBTC':
        case 'sBTC':
            return FuturesMarketAsset.sBTC;
        case 'wSOL':
        case 'swSOL':
            return FuturesMarketAsset.SOL;
        case 'sUSDe':
            return 'USDe';
        case 'scbETH':
        case 'cbETH':
            return 'cbETH';
        case 'scbBTC':
        case 'cbBTC':
            return 'cbBTC';
        case 'wstETH':
        case 'swstETH':
            return 'wstETH';
        case 'SUSD':
            return 'sUSD';
    }
    return token;
};
export const ORDERS_KEEPER_ADDRESSES = {
    84532: '0x1d1159B33a1b2A5b34B7E8A0300d1b6335bF51A1',
    8453: '0x1d1159B33a1b2A5b34B7E8A0300d1b6335bF51A1',
    421614: '0x1d1159B33a1b2A5b34B7E8A0300d1b6335bF51A1',
    42161: '0x1d1159B33a1b2A5b34B7E8A0300d1b6335bF51A1',
};
export const KWENTA_PERPS_V3_REFERRAL_ADDRESS = {
    [PerpsProvider.SNX_V3_BASE]: '0x3bD64247d879AF879e6f6e62F81430186391Bdb8',
    [PerpsProvider.SNX_V3_ARB]: '0x1813505eDB82a5c0Fc39DDB13213aC530Eb5DB6e',
};
export var DepositableV3AssetsBase;
(function (DepositableV3AssetsBase) {
    DepositableV3AssetsBase["USDC"] = "USDC";
    DepositableV3AssetsBase["WETH"] = "WETH";
    DepositableV3AssetsBase["ETH"] = "ETH";
    DepositableV3AssetsBase["cbETH"] = "cbETH";
    DepositableV3AssetsBase["cbBTC"] = "cbBTC";
    DepositableV3AssetsBase["wstETH"] = "wstETH";
})(DepositableV3AssetsBase || (DepositableV3AssetsBase = {}));
export var DepositableV3AssetsArb;
(function (DepositableV3AssetsArb) {
    DepositableV3AssetsArb["USDC"] = "USDC";
    DepositableV3AssetsArb["USDx"] = "USDx";
    DepositableV3AssetsArb["ETH"] = "ETH";
    DepositableV3AssetsArb["WETH"] = "WETH";
    DepositableV3AssetsArb["tBTC"] = "tBTC";
    DepositableV3AssetsArb["USDe"] = "USDe";
    // wSOL = 'wSOL', // Disabled due to liquidity
})(DepositableV3AssetsArb || (DepositableV3AssetsArb = {}));
export const DepositableAssetKeysV3 = { ...DepositableV3AssetsBase, ...DepositableV3AssetsArb };
export const WRAP_SLIPPAGE_MULTIPLIER = 0.999;
export const UNISWAP_FEE_500 = 500;
export const UNISWAP_FEE_100 = 100;
export const UNISWAP_POOL_FEES = {
    [SnxV3NetworkIds.ARB_MAINNET]: {
        [TOKEN_ADDRESSES.WETH[SnxV3NetworkIds.ARB_MAINNET]]: 500,
        [TOKEN_ADDRESSES.USDe[SnxV3NetworkIds.ARB_MAINNET]]: 100,
        [TOKEN_ADDRESSES.tBTC[SnxV3NetworkIds.ARB_MAINNET]]: 500,
    },
    [SnxV3NetworkIds.ARB_SEPOLIA]: {
        // TODO:
        [TOKEN_ADDRESSES.WETH[SnxV3NetworkIds.ARB_MAINNET]]: 500,
        [TOKEN_ADDRESSES.USDe[SnxV3NetworkIds.ARB_MAINNET]]: 100,
        [TOKEN_ADDRESSES.tBTC[SnxV3NetworkIds.ARB_MAINNET]]: 500,
    },
    [SnxV3NetworkIds.BASE_MAINNET]: {
        // TODO:
        [TOKEN_ADDRESSES.WETH[SnxV3NetworkIds.ARB_MAINNET]]: 500,
        [TOKEN_ADDRESSES.USDe[SnxV3NetworkIds.ARB_MAINNET]]: 100,
        [TOKEN_ADDRESSES.tBTC[SnxV3NetworkIds.ARB_MAINNET]]: 500,
    },
    [SnxV3NetworkIds.BASE_SEPOLIA]: {
        // TODO:
        [TOKEN_ADDRESSES.WETH[SnxV3NetworkIds.ARB_MAINNET]]: 500,
        [TOKEN_ADDRESSES.USDe[SnxV3NetworkIds.ARB_MAINNET]]: 100,
        [TOKEN_ADDRESSES.tBTC[SnxV3NetworkIds.ARB_MAINNET]]: 500,
    },
};
// https://github.com/JaredBorders/zap/blob/dd9ea4a4f93acd4a502ab3638971f4fc88a86553/src/Zap.sol#L561
export const ZAP_LOAN_BUFFER = wei(1);
export const predefinedOracles = (chainId) => {
    const provider = chainToV3Provider(chainId);
    const marketIds = Object.values(V3_PERPS_MARKET_IDS[provider]);
    const marketFeeds = marketIds.reduce((acc, marketId) => {
        const asset = v3PerpsMarketIdToAssetKey(chainId, marketId);
        const pythId = PERPS_PYTH_IDS[asset];
        if (pythId)
            acc.push(pythId);
        return acc;
    }, []);
    const collateralFeeds = Object.values(COLLATERAL_PYTH_IDS);
    return [...marketFeeds, ...collateralFeeds];
};
