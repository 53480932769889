import { wei } from '@kwenta/wei';
import { decodeErrorResult, encodeFunctionData, hexToString, } from 'viem';
import { DEFAULT_GAS_BUFFER, GWEI_DECIMALS } from '../constants/transactions';
const isKovan = (networkId) => networkId === 42;
export const getRevertReason = async ({ txHash, networkId, blockNumber, client, }) => {
    // Since we are using Infura, we cannot decode Kovan revert reasons
    if (isKovan(networkId))
        return 'Unable to decode revert reason';
    validateInputPreProvider(txHash);
    await validateInputPostProvider({ txHash, networkId, blockNumber, client });
    try {
        const tx = await client.getTransaction({ hash: txHash });
        const code = await client.call({
            to: tx.to,
            data: tx.input,
            blockNumber: blockNumber,
        });
        return decodeMessage(code.data);
    }
    catch (_err) {
        return 'Unable to decode revert reason';
    }
};
const validateInputPreProvider = (txHash) => {
    if (!/^0x([A-Fa-f0-9]{64})$/.test(txHash) || txHash.substring(0, 2) !== '0x') {
        throw new Error('Invalid transaction hash');
    }
};
async function validateInputPostProvider({ blockNumber, client }) {
    const currentBlockNumber = await client.getBlockNumber();
    if (blockNumber > currentBlockNumber) {
        throw new Error('You cannot use a block number that has not yet happened.');
    }
    // A block older than 128 blocks needs access to an archive node
    if (blockNumber < currentBlockNumber - BigInt(128))
        throw new Error('You cannot use a block number that is older than 128 blocks. Please use a provider that uses a full archival node.');
}
function decodeMessage(code) {
    let codeString = `0x${code.substring(138)}`.replace(/0+$/, '');
    // If the codeString is an odd number of characters, add a trailing 0
    if (codeString.length % 2 === 1) {
        codeString += '0';
    }
    return hexToString(codeString, { size: 32 });
}
export function createEmitter() {
    return {
        listeners: {},
        on: function (eventCode, listener) {
            switch (eventCode) {
                case 'txSent':
                case 'txConfirmed':
                case 'txFailed':
                case 'txError':
                    break;
                default:
                    throw new Error('Not a valid event');
            }
            if (typeof listener !== 'function') {
                throw new Error('Listener must be a function');
            }
            this.listeners[eventCode] = listener;
        },
        emit: function (eventCode, data) {
            if (this.listeners[eventCode]) {
                return this.listeners[eventCode](data);
            }
        },
    };
}
export const getTotalGasPrice = (gasPriceObj) => {
    if (!gasPriceObj)
        return wei(0);
    const { gasPrice, baseFeePerGas, maxPriorityFeePerGas } = gasPriceObj;
    if (gasPrice) {
        return wei(gasPrice, GWEI_DECIMALS);
    }
    return wei(baseFeePerGas || 0, GWEI_DECIMALS).add(wei(maxPriorityFeePerGas || 0, GWEI_DECIMALS));
};
export const getTransactionPrice = (gasPrice, gasLimit, ethPrice, optimismLayerOneFee) => {
    if (!gasPrice || !gasLimit || !ethPrice)
        return null;
    const totalGasPrice = getTotalGasPrice(gasPrice);
    const gasPriceCost = totalGasPrice.mul(wei(gasLimit, GWEI_DECIMALS)).mul(ethPrice);
    const l1Cost = ethPrice.mul(optimismLayerOneFee || 0);
    return gasPriceCost.add(l1Cost);
};
export const normalizeGasLimit = (gasLimit) => gasLimit + DEFAULT_GAS_BUFFER;
export const decodeTransactionError = (error, abis) => {
    const data = findErrorData(error);
    if (!data)
        return undefined;
    const decoded = abis.map((abi) => {
        try {
            return decodeErrorResult({
                abi,
                data,
            });
        }
        catch (_err) {
            return undefined;
        }
    });
    return decoded.find((x) => x !== undefined);
};
function isRawContractError(error) {
    return 'data' in error && 'code' in error && error.code === 3;
}
function findErrorData(error) {
    const originalError = error.walk();
    if (!isRawContractError(originalError)) {
        return undefined;
    }
    const { data } = originalError;
    if (!data)
        return undefined;
    return data instanceof Object && 'data' in data ? data.data : data;
}
export function isSimulatedRequest(request) {
    return (typeof request === 'object' &&
        request !== null &&
        'abi' in request &&
        'args' in request &&
        'address' in request &&
        'functionName' in request);
}
export const simulatedRequestToTxRequest = (simTx) => {
    return {
        to: simTx.address,
        data: encodeFunctionData({
            abi: simTx.abi,
            functionName: simTx.functionName,
            args: simTx.args,
        }),
        value: simTx.value || BigInt(0),
    };
};
