import Wei, { wei } from '@kwenta/wei';
import { KWENTA_TRACKING_CODE } from '../constants/futures';
import { PotentialTradeStatus } from '../types/futures';
import { divideDecimal, multiplyDecimal } from '../utils/number';
import { stringToHex, } from 'viem';
import { UNIT_BIG_INT, ZERO_BIG_INT } from '../constants';
import { SnxV2NetworkIds } from '../types';
import PerpsV2MarketABI from './abis/PerpsV2Market.js';
class FuturesMarketInternal {
    constructor(sdk, marketKey, marketAddress) {
        this.getTradePreview = async (account, sizeDelta, marginDelta, tradePrice) => {
            const config = {
                abi: PerpsV2MarketABI,
                address: this._marketAddress,
            };
            const [assetPrice, marketSkew, marketSize, accruedFunding, fundingSequenceLength, fundingLastRecomputed, fundingRateLastRecomputed, position,] = await this._client.multicall({
                allowFailure: false,
                contracts: [
                    {
                        ...config,
                        functionName: 'assetPrice',
                    },
                    {
                        ...config,
                        functionName: 'marketSkew',
                    },
                    {
                        ...config,
                        functionName: 'marketSize',
                    },
                    {
                        ...config,
                        functionName: 'accruedFunding',
                        args: [account],
                    },
                    {
                        ...config,
                        functionName: 'fundingSequenceLength',
                    },
                    {
                        ...config,
                        functionName: 'fundingLastRecomputed',
                    },
                    {
                        ...config,
                        functionName: 'fundingRateLastRecomputed',
                    },
                    {
                        ...config,
                        functionName: 'positions',
                        args: [account],
                    },
                ],
            });
            const blockNum = await this._client.getBlockNumber();
            this._block = await fetchBlockWithRetry(blockNum, this._client);
            this._onChainData = {
                assetPrice: assetPrice[0],
                marketSkew,
                marketSize,
                accruedFunding: accruedFunding[0],
                fundingSequenceLength,
                fundingLastRecomputed,
                fundingRateLastRecomputed: fundingRateLastRecomputed,
            };
            const takerFee = await this._getSetting('takerFeeOffchainDelayedOrder');
            const makerFee = await this._getSetting('makerFeeOffchainDelayedOrder');
            const fillPrice = await this._fillPrice(sizeDelta, tradePrice);
            const tradeParams = {
                sizeDelta: sizeDelta,
                fillPrice: fillPrice,
                desiredFillPrice: tradePrice,
                makerFee: makerFee,
                takerFee: takerFee,
                trackingCode: KWENTA_TRACKING_CODE,
            };
            const { newPos, fee, status } = await this._postTradeDetails(position, tradeParams, marginDelta);
            // if previous postion is closed, the remaining margin should not be used
            if (newPos.size === sizeDelta) {
                newPos.margin = marginDelta;
            }
            const liqPrice = await this._approxLiquidationPrice(newPos, newPos.lastPrice);
            return {
                ...newPos,
                liqPrice: liqPrice,
                fee,
                price: newPos.lastPrice,
                status: status,
            };
        };
        this._postTradeDetails = async (oldPos, tradeParams, marginDelta) => {
            // Reverts if the user is trying to submit a size-zero order.
            if (tradeParams.sizeDelta === ZERO_BIG_INT && marginDelta === ZERO_BIG_INT) {
                return {
                    newPos: oldPos,
                    fee: ZERO_BIG_INT,
                    status: PotentialTradeStatus.NIL_ORDER,
                };
            }
            // The order is not submitted if the user's existing position needs to be liquidated.
            if (await this._canLiquidate(oldPos, this._onChainData.assetPrice)) {
                return {
                    newPos: oldPos,
                    fee: ZERO_BIG_INT,
                    status: PotentialTradeStatus.CAN_LIQUIDATE,
                };
            }
            const fee = await this._orderFee(tradeParams);
            const { margin, status } = await this._recomputeMarginWithDelta(oldPos, tradeParams.fillPrice, marginDelta - fee);
            const lastFundingIndex = await this._latestFundingIndex();
            const newPos = {
                id: oldPos.id,
                lastFundingIndex: lastFundingIndex,
                margin: margin,
                lastPrice: tradeParams.fillPrice,
                size: oldPos.size + tradeParams.sizeDelta,
            };
            if (status !== PotentialTradeStatus.OK) {
                return { newPos, fee: ZERO_BIG_INT, status };
            }
            const minInitialMargin = await this._getSetting('minInitialMargin');
            const zeroSize = newPos.size === ZERO_BIG_INT;
            const positiveOldSize = oldPos.size >= ZERO_BIG_INT;
            const positiveNewSize = newPos.size >= ZERO_BIG_INT;
            const positionDecreasing = zeroSize ||
                (positiveOldSize === positiveNewSize && wei(newPos.size).abs().lt(wei(oldPos.size).abs()));
            const positionChanged = newPos.size !== oldPos.size;
            if (!positionDecreasing && positionChanged) {
                if (newPos.margin + fee < minInitialMargin) {
                    return {
                        newPos,
                        fee: ZERO_BIG_INT,
                        status: PotentialTradeStatus.INSUFFICIENT_MARGIN,
                    };
                }
            }
            const liqPremium = await this._liquidationPremium(newPos.size, this._onChainData.assetPrice);
            let liqMargin = await this._liquidationMargin(newPos.size, this._onChainData.assetPrice);
            liqMargin = liqMargin + liqPremium;
            if (margin <= liqMargin) {
                return {
                    newPos,
                    fee: ZERO_BIG_INT,
                    status: PotentialTradeStatus.CAN_LIQUIDATE,
                };
            }
            const maxLeverage = await this._getSetting('maxLeverage');
            const maxLeverageForSize = await this._maxLeverageForSize(newPos.size);
            const leverage = divideDecimal(multiplyDecimal(newPos.size, tradeParams.fillPrice), margin + fee);
            if (!positionDecreasing &&
                marginDelta <= 0n &&
                (maxLeverage + UNIT_BIG_INT / BigInt(100) < wei(leverage).abs().toBigInt() ||
                    wei(leverage).abs().gt(maxLeverageForSize))) {
                return {
                    newPos,
                    fee: ZERO_BIG_INT,
                    status: PotentialTradeStatus.MAX_LEVERAGE_EXCEEDED,
                };
            }
            const maxMarketValue = await this._getSetting('maxMarketValue');
            const tooLarge = await this._orderSizeTooLarge(maxMarketValue, oldPos.size, newPos.size);
            if (tooLarge) {
                return {
                    newPos,
                    fee: ZERO_BIG_INT,
                    status: PotentialTradeStatus.MAX_MARKET_SIZE_EXCEEDED,
                };
            }
            return { newPos, fee: fee, status: PotentialTradeStatus.OK };
        };
        this._liquidationPremium = async (positionSize, currentPrice) => {
            if (positionSize === ZERO_BIG_INT) {
                return ZERO_BIG_INT;
            }
            // note: this is the same as fillPrice() where the skew is 0.
            const notional = wei(multiplyDecimal(positionSize, currentPrice)).abs().toBigInt();
            const skewScale = await this._getSetting('skewScale');
            const liqPremiumMultiplier = await this._getSetting('liquidationPremiumMultiplier');
            const skewedSize = divideDecimal(wei(positionSize).abs().toBigInt(), skewScale);
            const value = multiplyDecimal(skewedSize, notional);
            return multiplyDecimal(value, liqPremiumMultiplier);
        };
        this._orderFee = (tradeParams) => {
            const notionalDiff = multiplyDecimal(tradeParams.sizeDelta, tradeParams.fillPrice);
            const marketSkew = this._onChainData.marketSkew;
            if (this._sameSide(marketSkew + tradeParams.sizeDelta, marketSkew)) {
                const staticRate = this._sameSide(notionalDiff, marketSkew)
                    ? tradeParams.takerFee
                    : tradeParams.makerFee;
                return wei(multiplyDecimal(notionalDiff, staticRate)).abs().toBigInt();
            }
            // IGNORED DYNAMIC FEE //
            const takerSize = wei(divideDecimal(marketSkew + tradeParams.sizeDelta, tradeParams.sizeDelta))
                .abs()
                .toBigInt();
            const makerSize = UNIT_BIG_INT - takerSize;
            const takerFee = wei(multiplyDecimal(multiplyDecimal(notionalDiff, takerSize), tradeParams.takerFee))
                .abs()
                .toBigInt();
            const makerFee = wei(multiplyDecimal(multiplyDecimal(notionalDiff, makerSize), tradeParams.makerFee))
                .abs()
                .toBigInt();
            return takerFee + makerFee;
        };
        this._recomputeMarginWithDelta = async (position, price, marginDelta) => {
            const marginPlusProfitFunding = await this._marginPlusProfitFunding(position, price);
            const newMargin = marginPlusProfitFunding + marginDelta;
            if (newMargin < ZERO_BIG_INT) {
                return {
                    margin: ZERO_BIG_INT,
                    status: PotentialTradeStatus.INSUFFICIENT_MARGIN,
                };
            }
            const lMargin = await this._liquidationMargin(position.size, price);
            if (position.size !== ZERO_BIG_INT && newMargin < lMargin) {
                return { margin: newMargin, status: PotentialTradeStatus.CAN_LIQUIDATE };
            }
            return { margin: newMargin, status: PotentialTradeStatus.OK };
        };
        this._marginPlusProfitFunding = async (position, price) => {
            const funding = this._onChainData.accruedFunding;
            return position.margin + this._profitLoss(position, price) + funding;
        };
        this._profitLoss = (position, price) => {
            const priceShift = price - position.lastPrice;
            return multiplyDecimal(position.size, priceShift);
        };
        this._nextFundingEntry = async (price) => {
            const latestFundingIndex = await this._latestFundingIndex();
            const fundingSequenceVal = await this._client.readContract({
                abi: PerpsV2MarketABI,
                address: this._marketAddress,
                functionName: 'fundingSequence',
                args: [latestFundingIndex],
            });
            const unrecordedFunding = await this._unrecordedFunding(price);
            return fundingSequenceVal + unrecordedFunding;
        };
        this._latestFundingIndex = async () => {
            const fundingSequenceLength = this._onChainData.fundingSequenceLength;
            return fundingSequenceLength - BigInt(1); // at least one element is pushed in constructor
        };
        this._netFundingPerUnit = async (startIndex, price) => {
            const fundingSequenceVal = await this._client.readContract({
                abi: PerpsV2MarketABI,
                address: this._marketAddress,
                functionName: 'fundingSequence',
                args: [startIndex],
            });
            const nextFunding = await this._nextFundingEntry(price);
            return nextFunding - fundingSequenceVal;
        };
        this._proportionalElapsed = async () => {
            // TODO: get block at the start
            if (!this._block)
                throw new Error('Missing block data');
            const fundingLastRecomputed = this._onChainData.fundingLastRecomputed;
            const rate = this._block.timestamp - BigInt(fundingLastRecomputed);
            return divideDecimal(rate, BigInt(86400));
        };
        this._currentFundingVelocity = async () => {
            const maxFundingVelocity = await this._getSetting('maxFundingVelocity');
            const skew = await this._proportionalSkew();
            return multiplyDecimal(skew, maxFundingVelocity);
        };
        this._currentFundingRate = async () => {
            const fundingRateLastRecomputed = this._onChainData.fundingRateLastRecomputed;
            const elapsed = await this._proportionalElapsed();
            const velocity = await this._currentFundingVelocity();
            return fundingRateLastRecomputed + multiplyDecimal(velocity, elapsed);
        };
        this._unrecordedFunding = async (price) => {
            const fundingRateLastRecomputed = this._onChainData.fundingRateLastRecomputed;
            const nextFundingRate = await this._currentFundingRate();
            const elapsed = await this._proportionalElapsed();
            const avgFundingRate = divideDecimal(fundingRateLastRecomputed + nextFundingRate * BigInt(-1), UNIT_BIG_INT * BigInt(2));
            return multiplyDecimal(multiplyDecimal(avgFundingRate, elapsed), price);
        };
        this._proportionalSkew = async () => {
            const marketSkew = await this._onChainData.marketSkew;
            const skewScale = await this._getSetting('skewScale');
            const pSkew = divideDecimal(marketSkew, skewScale);
            // Ensures the proportionalSkew is between -1 and 1.
            const proportionalSkew = Wei.min(Wei.max(wei(UNIT_BIG_INT).neg(), wei(pSkew)), wei(UNIT_BIG_INT));
            return proportionalSkew.toBigInt();
        };
        this._approxLiquidationPrice = async (position, currentPrice) => {
            if (position.size === ZERO_BIG_INT) {
                return ZERO_BIG_INT;
            }
            const fundingPerUnit = await this._netFundingPerUnit(position.lastFundingIndex, currentPrice);
            const liqMargin = await this._liquidationMargin(position.size, currentPrice);
            const liqPremium = await this._liquidationPremium(position.size, currentPrice);
            const result = position.lastPrice +
                divideDecimal(liqMargin - position.margin - liqPremium, position.size) -
                fundingPerUnit;
            return result < 0 ? ZERO_BIG_INT : result;
        };
        this._exactLiquidationMargin = async (positionSize, price) => {
            const keeperFee = await this._liquidationFee(positionSize, price);
            const stakerFee = await this._stakerFee(positionSize, price);
            return keeperFee + stakerFee;
        };
        this._liquidationMargin = async (positionSize, price) => {
            const liquidationBufferRatio = await this._getSetting('liquidationBufferRatio');
            const liqKeeperFee = await this._getSetting('keeperLiquidationFee');
            const liquidationBuffer = multiplyDecimal(multiplyDecimal(wei(positionSize).abs().toBigInt(), price), liquidationBufferRatio);
            const fee = await this._liquidationFee(positionSize, price);
            return liquidationBuffer + fee + liqKeeperFee;
        };
        this._liquidationFee = async (positionSize, price) => {
            const liquidationFeeRatio = await this._getSetting('liquidationFeeRatio');
            const minFee = await this._getSetting('minKeeperFee');
            const maxFee = await this._getSetting('maxKeeperFee');
            const proportionalFee = multiplyDecimal(multiplyDecimal(wei(positionSize).abs().toBigInt(), price), liquidationFeeRatio);
            const cappedProportionalFee = proportionalFee > maxFee ? maxFee : proportionalFee;
            return cappedProportionalFee > minFee ? proportionalFee : minFee;
        };
        this._stakerFee = async (positionSize, price) => {
            const liquidationBufferRatio = await this._getSetting('liquidationBufferRatio');
            const stakerFee = multiplyDecimal(multiplyDecimal(wei(positionSize).abs().toBigInt(), price), liquidationBufferRatio);
            return stakerFee;
        };
        this._fillPrice = async (size, price) => {
            const marketSkew = await this._onChainData.marketSkew;
            const skewScale = await this._getSetting('skewScale');
            const pdBefore = divideDecimal(marketSkew, skewScale);
            const pdAfter = divideDecimal(marketSkew + size, skewScale);
            const priceBefore = price + multiplyDecimal(price, pdBefore);
            const priceAfter = price + multiplyDecimal(price, pdAfter);
            // How is the p/d-adjusted price calculated using an example:
            //
            // price      = $1200 USD (oracle)
            // size       = 100
            // skew       = 0
            // skew_scale = 1,000,000 (1M)
            //
            // Then,
            //
            // pd_before = 0 / 1,000,000
            //           = 0
            // pd_after  = (0 + 100) / 1,000,000
            //           = 100 / 1,000,000
            //           = 0.0001
            //
            // price_before = 1200 * (1 + pd_before)
            //              = 1200 * (1 + 0)
            //              = 1200
            // price_after  = 1200 * (1 + pd_after)
            //              = 1200 * (1 + 0.0001)
            //              = 1200 * (1.0001)
            //              = 1200.12
            // Finally,
            //
            // fill_price = (price_before + price_after) / 2
            //            = (1200 + 1200.12) / 2
            //            = 1200.06
            return divideDecimal(priceBefore + priceAfter, UNIT_BIG_INT * BigInt(2));
        };
        this._canLiquidate = async (position, price) => {
            // No liquidating empty positions.
            if (position.size === ZERO_BIG_INT) {
                return false;
            }
            const remainingLiquidatableMargin = await this._remainingLiquidatableMargin(position, price);
            const liqMargin = await this._liquidationMargin(position.size, price);
            return remainingLiquidatableMargin < liqMargin;
        };
        this._remainingLiquidatableMargin = async (position, price) => {
            const liqPremium = await this._liquidationPremium(position.size, price);
            const marginPlusProfitFunding = await this._marginPlusProfitFunding(position, price);
            const remaining = marginPlusProfitFunding - liqPremium;
            return remaining > ZERO_BIG_INT ? remaining : ZERO_BIG_INT;
        };
        this._orderSizeTooLarge = async (maxSize, oldSize, newSize) => {
            if ((this._sameSide(oldSize, newSize) && wei(newSize).abs().lte(wei(oldSize).abs())) ||
                newSize === ZERO_BIG_INT) {
                return false;
            }
            const marketSkew = this._onChainData.marketSkew;
            const marketSize = this._onChainData.marketSize;
            const newSkew = marketSkew - oldSize + newSize;
            const newMarketSize = wei(marketSize).sub(wei(oldSize).abs()).add(wei(newSize).abs()).toBigInt();
            let newSideSize;
            if (newSize > ZERO_BIG_INT) {
                newSideSize = newMarketSize + newSkew;
            }
            else {
                newSideSize = newMarketSize - newSkew;
            }
            if (maxSize < wei(newSideSize).div(2).abs().toBigInt()) {
                return true;
            }
            return false;
        };
        this._maxLeverageForSize = async (size) => {
            const skewScale = await this._getSetting('skewScale');
            const liqPremMultiplier = await this._getSetting('liquidationPremiumMultiplier');
            const liqBufferRatio = await this._getSetting('liquidationBufferRatio');
            const liqBuffer = wei(0.5);
            const liqBufferRatioWei = wei(liqBufferRatio);
            const liqPremMultiplierWei = wei(liqPremMultiplier);
            const skewScaleWei = wei(skewScale);
            return liqBuffer
                .div(wei(size).abs().div(skewScaleWei).mul(liqPremMultiplierWei).add(liqBufferRatioWei))
                .toBigInt();
        };
        this._batchGetSettings = async () => {
            const config = this._sdk.context.contractConfigs[this._chainId]?.snxV2.PerpsV2MarketSettings;
            if (!config)
                throw new Error('Unsupported network');
            const [minInitialMargin, takerFeeOffchainDelayedOrder, makerFeeOffchainDelayedOrder, maxLeverage, maxMarketValue, skewScale, liquidationPremiumMultiplier, maxFundingVelocity, liquidationBufferRatio, liquidationFeeRatio, maxKeeperFee, minKeeperFee, keeperLiquidationFee,] = await this._client.multicall({
                allowFailure: false,
                contracts: [
                    {
                        ...config,
                        functionName: 'minInitialMargin',
                    },
                    {
                        ...config,
                        functionName: 'takerFeeOffchainDelayedOrder',
                        args: [this._marketKeyBytes],
                    },
                    {
                        ...config,
                        functionName: 'makerFeeOffchainDelayedOrder',
                        args: [this._marketKeyBytes],
                    },
                    {
                        ...config,
                        functionName: 'maxLeverage',
                        args: [this._marketKeyBytes],
                    },
                    {
                        ...config,
                        functionName: 'maxMarketValue',
                        args: [this._marketKeyBytes],
                    },
                    {
                        ...config,
                        functionName: 'skewScale',
                        args: [this._marketKeyBytes],
                    },
                    {
                        ...config,
                        functionName: 'liquidationPremiumMultiplier',
                        args: [this._marketKeyBytes],
                    },
                    {
                        ...config,
                        functionName: 'maxFundingVelocity',
                        args: [this._marketKeyBytes],
                    },
                    {
                        ...config,
                        functionName: 'liquidationBufferRatio',
                        args: [this._marketKeyBytes],
                    },
                    {
                        ...config,
                        functionName: 'liquidationFeeRatio',
                    },
                    {
                        ...config,
                        functionName: 'maxKeeperFee',
                    },
                    {
                        ...config,
                        functionName: 'minKeeperFee',
                    },
                    {
                        ...config,
                        functionName: 'keeperLiquidationFee',
                    },
                ],
            });
            this._marketSettings = {
                minInitialMargin,
                takerFeeOffchainDelayedOrder,
                makerFeeOffchainDelayedOrder,
                maxLeverage,
                maxMarketValue,
                skewScale,
                liquidationPremiumMultiplier,
                maxFundingVelocity,
                liquidationBufferRatio,
                liquidationFeeRatio,
                maxKeeperFee,
                minKeeperFee,
                keeperLiquidationFee,
            };
            return this._marketSettings;
        };
        this._getSetting = async (settingType) => {
            if (this._marketSettings)
                return this._marketSettings[settingType];
            const settings = await this._batchGetSettings();
            return settings[settingType];
        };
        this._sdk = sdk;
        this._marketAddress = marketAddress;
        this._marketKeyBytes = stringToHex(marketKey, { size: 32 });
        this._cache = {};
        this._block = null;
        this._onChainData = {
            assetPrice: ZERO_BIG_INT,
            marketSkew: ZERO_BIG_INT,
            marketSize: ZERO_BIG_INT,
            fundingSequenceLength: ZERO_BIG_INT,
            fundingLastRecomputed: 0,
            fundingRateLastRecomputed: ZERO_BIG_INT,
            accruedFunding: ZERO_BIG_INT,
        };
        this._chainId =
            this._sdk.context.signerChainId === SnxV2NetworkIds.OPTIMISM_SEPOLIA
                ? SnxV2NetworkIds.OPTIMISM_SEPOLIA
                : SnxV2NetworkIds.OPTIMISM_MAINNET;
        this._client = this._sdk.context.clients[this._chainId];
    }
    _sameSide(a, b) {
        const aPositive = a >= ZERO_BIG_INT;
        const bPositive = b >= ZERO_BIG_INT;
        return aPositive === bPositive;
    }
}
const fetchBlockWithRetry = async (blockNumber, client, count = 0) => {
    // Sometimes the block number is returned before the block
    // is ready to fetch and so getBlock returns null
    const block = await client.getBlock({ blockNumber });
    if (block)
        return block;
    if (count > 5)
        return null;
    await new Promise((resolve) => setTimeout(resolve, 200));
    return fetchBlockWithRetry(blockNumber, client, count + 1);
};
export default FuturesMarketInternal;
