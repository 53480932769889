import { wei } from '@kwenta/wei';
import moment from 'moment';
import { hexToBigInt, maxUint256 } from 'viem';
import { arbitrum, optimism } from 'viem/chains';
import { KWENTA_ADDRESS } from '../constants/common';
import * as sdkErrors from '../constants/errors';
import { AGGREGATE_ASSET_KEY, KWENTA_TRACKING_CODE } from '../constants/futures';
import { ZERO_BIG_INT, ZERO_WEI } from '../constants/number';
import { SECONDS_PER_DAY } from '../constants/period';
import { ARB_TRADING_REWARDS_END_EPOCH, DEFAULT_NUMBER_OF_FUTURES_FEE, EPOCH_CONFIGS, REFERRAL_PROGRAM_END_EPOCH, REFERRAL_PROGRAM_START_EPOCH, STAKING_V2_REWARDS_CUTOFF_EPOCH, STAKING_V2_REWARDS_END_EPOCH, TRADING_REWARDS_CUTOFF_EPOCH, } from '../constants/staking';
import { getFuturesAggregateStats, getFuturesTrades } from '../queries/futures';
import { queryOperatorsByOwner } from '../queries/staking';
import { calculateFeesForAccount, calculateTotalFees, getEpochPeriod, getPerpsV2SubgraphUrl, getStakingGqlEndpoint, } from '../utils';
import { formatTruncatedDuration } from '../utils/date';
import { awsClient } from '../utils/files';
import { weiFromWei } from '../utils/number';
export default class KwentaTokenService {
    constructor(sdk) {
        this.sdk = sdk;
    }
    get stakingGqlEndpoint() {
        return getStakingGqlEndpoint(10);
    }
    get client() {
        return this.sdk.context.clients[10] ?? this.sdk.context.signerPublicClient;
    }
    get arbitrumClient() {
        return this.sdk.context.clients[42161] ?? this.sdk.context.signerPublicClient;
    }
    get contractConfigs() {
        return this.sdk.context.contractConfigs[10];
    }
    switchToOptimismAndWriteContact(params) {
        return this.sdk.transactions.writeContract(params, optimism.id);
    }
    async changePoolTokens(amount, action) {
        const { StakingRewards } = this.contractConfigs.kwenta;
        if (!StakingRewards) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        return this.sdk.transactions.writeContract({
            ...StakingRewards,
            functionName: action,
            args: [BigInt(amount)],
        });
    }
    async approveLPToken() {
        const { KwentaArrakisVault, StakingRewards } = this.contractConfigs.kwenta;
        if (!KwentaArrakisVault) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        const spenderAddress = StakingRewards ? StakingRewards.address : this.sdk.context.walletAddress;
        return this.sdk.transactions.writeContract({
            ...KwentaArrakisVault,
            functionName: 'approve',
            args: [spenderAddress, maxUint256],
        });
    }
    async getEarnDetails() {
        const { StakingRewards, KwentaArrakisVault } = this.contractConfigs.kwenta;
        if (!StakingRewards || !KwentaArrakisVault) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        const { walletAddress } = this.sdk.context;
        const [balance, earned, periodFinish, rewardRate, totalSupply, lpTokenBalance, allowance, [wethAmount, kwentaAmount], lpTotalSupply,] = await this.client.multicall({
            allowFailure: false,
            contracts: [
                {
                    ...StakingRewards,
                    functionName: 'balanceOf',
                    args: [walletAddress],
                },
                {
                    ...StakingRewards,
                    functionName: 'earned',
                    args: [walletAddress],
                },
                {
                    ...StakingRewards,
                    functionName: 'periodFinish',
                },
                {
                    ...StakingRewards,
                    functionName: 'rewardRate',
                },
                {
                    ...StakingRewards,
                    functionName: 'totalSupply',
                },
                {
                    ...KwentaArrakisVault,
                    functionName: 'balanceOf',
                    args: [walletAddress],
                },
                {
                    ...KwentaArrakisVault,
                    functionName: 'allowance',
                    args: [walletAddress, StakingRewards.address],
                },
                {
                    ...KwentaArrakisVault,
                    functionName: 'getUnderlyingBalances',
                },
                {
                    ...KwentaArrakisVault,
                    functionName: 'totalSupply',
                },
            ],
        });
        return {
            balance: wei(balance),
            earned: wei(earned),
            endDate: Number(periodFinish),
            rewardRate: wei(rewardRate),
            totalSupply: wei(totalSupply),
            lpTokenBalance: wei(lpTokenBalance),
            allowance: wei(allowance),
            wethAmount: wei(wethAmount),
            kwentaAmount: wei(kwentaAmount),
            lpTotalSupply: wei(lpTotalSupply),
        };
    }
    async getEarnTokenPrices() {
        const coinGeckoPrices = await this.sdk.prices.batchGetCoingeckoPrices([KWENTA_ADDRESS]);
        const { ARB, OP, SNX, sETH } = await this.sdk.prices.currentPrices.offChain;
        return {
            kwentaPrice: coinGeckoPrices ? wei(coinGeckoPrices[KWENTA_ADDRESS]?.usd) : ZERO_WEI,
            arbPrice: ARB ?? ZERO_WEI,
            opPrice: OP ?? ZERO_WEI,
            snxPrice: SNX ?? ZERO_WEI,
            wethPrice: sETH ?? ZERO_WEI,
        };
    }
    async claimRewards() {
        const { StakingRewards } = this.contractConfigs.kwenta;
        if (!StakingRewards) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        return this.switchToOptimismAndWriteContact({
            ...StakingRewards,
            functionName: 'getReward',
        });
    }
    async getStakingData() {
        const { vKwentaRedeemer, veKwentaRedeemer, RewardEscrow, KwentaStakingRewards, KwentaToken, SupplySchedule, vKwentaToken, veKwentaToken, } = this.contractConfigs.kwenta;
        if (!RewardEscrow ||
            !KwentaStakingRewards ||
            !KwentaToken ||
            !SupplySchedule ||
            !vKwentaToken ||
            !veKwentaToken ||
            !vKwentaRedeemer ||
            !veKwentaRedeemer) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        const { walletAddress } = this.sdk.context;
        const [rewardEscrowBalance, stakedNonEscrowedBalance, stakedEscrowedBalance, claimableBalance, kwentaBalance, weekCounter, totalStakedBalance, vKwentaBalance, vKwentaAllowance, kwentaAllowance, veKwentaBalance, veKwentaAllowance,] = await this.client.multicall({
            allowFailure: false,
            contracts: [
                {
                    ...RewardEscrow,
                    functionName: 'balanceOf',
                    args: [walletAddress],
                },
                {
                    ...KwentaStakingRewards,
                    functionName: 'nonEscrowedBalanceOf',
                    args: [walletAddress],
                },
                {
                    ...KwentaStakingRewards,
                    functionName: 'escrowedBalanceOf',
                    args: [walletAddress],
                },
                {
                    ...KwentaStakingRewards,
                    functionName: 'earned',
                    args: [walletAddress],
                },
                {
                    ...KwentaToken,
                    functionName: 'balanceOf',
                    args: [walletAddress],
                },
                {
                    ...SupplySchedule,
                    functionName: 'weekCounter',
                },
                {
                    ...KwentaStakingRewards,
                    functionName: 'totalSupply',
                },
                {
                    ...vKwentaToken,
                    functionName: 'balanceOf',
                    args: [walletAddress],
                },
                {
                    ...vKwentaToken,
                    functionName: 'allowance',
                    args: [walletAddress, vKwentaRedeemer.address],
                },
                {
                    ...KwentaToken,
                    functionName: 'allowance',
                    args: [walletAddress, KwentaStakingRewards.address],
                },
                {
                    ...veKwentaToken,
                    functionName: 'balanceOf',
                    args: [walletAddress],
                },
                {
                    ...veKwentaToken,
                    functionName: 'allowance',
                    args: [walletAddress, veKwentaRedeemer.address],
                },
            ],
        });
        return {
            rewardEscrowBalance: wei(rewardEscrowBalance),
            stakedNonEscrowedBalance: wei(stakedNonEscrowedBalance),
            stakedEscrowedBalance: wei(stakedEscrowedBalance),
            claimableBalance: wei(claimableBalance),
            kwentaBalance: wei(kwentaBalance),
            weekCounter: Number(weekCounter),
            totalStakedBalance: wei(totalStakedBalance),
            vKwentaBalance: wei(vKwentaBalance),
            vKwentaAllowance: wei(vKwentaAllowance),
            kwentaAllowance: wei(kwentaAllowance),
            veKwentaBalance: wei(veKwentaBalance),
            veKwentaAllowance: wei(veKwentaAllowance),
        };
    }
    async getStakingV2Data() {
        const { RewardEscrowV2, KwentaStakingRewardsV2, KwentaToken, SupplySchedule } = this.contractConfigs.kwenta;
        if (!RewardEscrowV2 || !KwentaStakingRewardsV2 || !KwentaToken || !SupplySchedule) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        const { walletAddress } = this.sdk.context;
        const [rewardEscrowBalance, stakedNonEscrowedBalance, stakedEscrowedBalance, claimableBalance, feeShareBalance, totalStakedBalance, lastStakedTime, cooldownPeriod, kwentaStakingV2Allowance,] = await this.client.multicall({
            allowFailure: false,
            contracts: [
                {
                    ...RewardEscrowV2,
                    functionName: 'escrowedBalanceOf',
                    args: [walletAddress],
                },
                {
                    ...KwentaStakingRewardsV2,
                    functionName: 'nonEscrowedBalanceOf',
                    args: [walletAddress],
                },
                {
                    ...KwentaStakingRewardsV2,
                    functionName: 'escrowedBalanceOf',
                    args: [walletAddress],
                },
                {
                    ...KwentaStakingRewardsV2,
                    functionName: 'earned',
                    args: [walletAddress],
                },
                {
                    ...KwentaStakingRewardsV2,
                    functionName: 'earnedUSDC',
                    args: [walletAddress],
                },
                {
                    ...KwentaStakingRewardsV2,
                    functionName: 'totalSupply',
                },
                {
                    ...KwentaStakingRewardsV2,
                    functionName: 'userLastStakeTime',
                    args: [walletAddress],
                },
                {
                    ...KwentaStakingRewardsV2,
                    functionName: 'cooldownPeriod',
                },
                {
                    ...KwentaToken,
                    functionName: 'allowance',
                    args: [walletAddress, KwentaStakingRewardsV2.address],
                },
            ],
        });
        return {
            rewardEscrowBalance: wei(rewardEscrowBalance),
            stakedNonEscrowedBalance: wei(stakedNonEscrowedBalance),
            stakedEscrowedBalance: wei(stakedEscrowedBalance),
            claimableBalance: wei(claimableBalance),
            feeShareBalance: wei(feeShareBalance),
            totalStakedBalance: wei(totalStakedBalance),
            stakedResetTime: Number(lastStakedTime) + Number(cooldownPeriod),
            kwentaStakingV2Allowance: wei(kwentaStakingV2Allowance),
        };
    }
    async getEscrowData() {
        const { RewardEscrow } = this.contractConfigs.kwenta;
        if (!RewardEscrow) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        const { walletAddress } = this.sdk.context;
        const schedules = await this.client.readContract({
            ...RewardEscrow,
            functionName: 'getVestingSchedules',
            args: [walletAddress, ZERO_BIG_INT, DEFAULT_NUMBER_OF_FUTURES_FEE],
        });
        const vestingSchedules = schedules
            .filter((schedule) => schedule.escrowAmount > ZERO_BIG_INT)
            .sort((a, b) => Number(a.endTime) - Number(b.endTime));
        const { escrowData, totalVestable } = vestingSchedules.reduce((acc, next, _i) => {
            const date = Number(next.endTime) * 1000;
            acc.totalVestable = acc.totalVestable.add(wei(next.escrowAmount));
            acc.escrowData.push({
                id: Number(next.entryID),
                date: moment(date).format('MM/DD/YY'),
                time: formatTruncatedDuration(0),
                vestable: wei(next.escrowAmount),
                amount: wei(next.escrowAmount),
                fee: ZERO_WEI,
                status: 'Vested',
                version: 1,
            });
            return acc;
        }, { escrowData: [], totalVestable: wei(0) });
        return { escrowData, totalVestable };
    }
    async getEscrowV2Data() {
        const { RewardEscrowV2 } = this.contractConfigs.kwenta;
        if (!RewardEscrowV2) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        const { walletAddress } = this.sdk.context;
        const schedules = await this.client.readContract({
            ...RewardEscrowV2,
            functionName: 'getVestingSchedules',
            args: [walletAddress, ZERO_BIG_INT, DEFAULT_NUMBER_OF_FUTURES_FEE],
        });
        const vestingSchedules = schedules
            .filter((schedule) => schedule.escrowAmount > ZERO_BIG_INT)
            .sort((a, b) => Number(a.endTime) - Number(b.endTime));
        const { escrowData, totalVestable } = vestingSchedules.reduce((acc, next, _i) => {
            const date = Number(next.endTime) * 1000;
            acc.totalVestable = acc.totalVestable.add(wei(next.escrowAmount));
            acc.escrowData.push({
                id: Number(next.entryID),
                date: moment(date).format('MM/DD/YY'),
                time: formatTruncatedDuration(0),
                vestable: wei(next.escrowAmount),
                amount: wei(next.escrowAmount),
                fee: ZERO_WEI,
                status: 'Vested',
                version: 2,
            });
            return acc;
        }, { escrowData: [], totalVestable: wei(0) });
        return { escrowData, totalVestable };
    }
    claimStakingRewards() {
        const { KwentaStakingRewards } = this.contractConfigs.kwenta;
        if (!KwentaStakingRewards) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        return this.switchToOptimismAndWriteContact({
            ...KwentaStakingRewards,
            functionName: 'getReward',
        });
    }
    async claimStakingRewardsV2() {
        const { KwentaStakingRewardsV2 } = this.contractConfigs.kwenta;
        if (!KwentaStakingRewardsV2) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        return this.switchToOptimismAndWriteContact({
            ...KwentaStakingRewardsV2,
            functionName: 'getReward',
        });
    }
    async compoundRewards() {
        const { KwentaStakingRewardsV2 } = this.contractConfigs.kwenta;
        if (!KwentaStakingRewardsV2) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        return this.switchToOptimismAndWriteContact({
            ...KwentaStakingRewardsV2,
            functionName: 'compound',
        });
    }
    // TODO: Replace this with separate functions that use `approveToken`
    // In that case, we can safely remove the map object from this method.
    async approveKwentaToken(token, amount = maxUint256) {
        const { KwentaToken, KwentaStakingRewards, vKwentaToken, vKwentaRedeemer, veKwentaToken, veKwentaRedeemer, KwentaStakingRewardsV2, } = this.contractConfigs.kwenta;
        const map = {
            kwenta: { contract: KwentaToken, spender: KwentaStakingRewards },
            vKwenta: { contract: vKwentaToken, spender: vKwentaRedeemer },
            veKwenta: { contract: veKwentaToken, spender: veKwentaRedeemer },
            kwentaStakingV2: { contract: KwentaToken, spender: KwentaStakingRewardsV2 },
        };
        const { contract, spender } = map[token];
        if (!contract || !spender) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        return this.switchToOptimismAndWriteContact({
            ...contract,
            functionName: 'approve',
            args: [spender.address, amount],
        });
    }
    async redeemVKwenta() {
        const { vKwentaRedeemer } = this.contractConfigs.kwenta;
        if (!vKwentaRedeemer) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        return this.switchToOptimismAndWriteContact({
            ...vKwentaRedeemer,
            functionName: 'redeem',
        });
    }
    redeemVeKwenta() {
        const { veKwentaRedeemer } = this.contractConfigs.kwenta;
        if (!veKwentaRedeemer) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        return this.switchToOptimismAndWriteContact({
            ...veKwentaRedeemer,
            functionName: 'redeem',
            args: [this.sdk.context.walletAddress],
        });
    }
    vestToken(ids) {
        const { RewardEscrow } = this.contractConfigs.kwenta;
        if (!RewardEscrow) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        return this.switchToOptimismAndWriteContact({
            ...RewardEscrow,
            functionName: 'vest',
            args: [ids],
        });
    }
    async vestTokenV2(ids) {
        const { RewardEscrowV2 } = this.contractConfigs.kwenta;
        if (!RewardEscrowV2) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        return this.switchToOptimismAndWriteContact({
            ...RewardEscrowV2,
            functionName: 'vest',
            args: [ids],
        });
    }
    stakeKwenta(amount) {
        return this.performStakeAction('stake', amount);
    }
    unstakeKwenta(amount) {
        return this.performStakeAction('unstake', amount, { escrow: false, version: 1 });
    }
    stakeEscrowedKwenta(amount) {
        return this.performStakeAction('stake', amount, { escrow: true, version: 1 });
    }
    unstakeEscrowedKwenta(amount) {
        return this.performStakeAction('unstake', amount, { escrow: true, version: 1 });
    }
    stakeKwentaV2(amount) {
        return this.performStakeAction('stake', amount, { escrow: false, version: 2 });
    }
    unstakeKwentaV2(amount) {
        return this.performStakeAction('unstake', amount, { escrow: false, version: 2 });
    }
    stakeEscrowedKwentaV2(amount) {
        return this.performStakeAction('stake', amount, { escrow: true, version: 2 });
    }
    unstakeEscrowedKwentaV2(amount) {
        return this.performStakeAction('unstake', amount, { escrow: true, version: 2 });
    }
    async getEstimatedRewards() {
        const { walletAddress } = this.sdk.context;
        const epochPeriodArb = getEpochPeriod(EPOCH_CONFIGS.arbitrum);
        const epochPeriodOp = getEpochPeriod(EPOCH_CONFIGS.opReferral);
        const fileNames = epochPeriodArb < 0
            ? ['/epoch-current.json']
            : ['', '-arb'].map((i) => `/epoch-current${i}.json`);
        if (epochPeriodOp >= 0) {
            fileNames.push('/epoch-current-op-referral.json');
        }
        const responses = await Promise.all(fileNames.map(async (fileName) => {
            const response = await awsClient.get(fileName);
            return { ...response.data };
        }));
        const [estimatedKwentaRewards, estimatedArbRewards, estimatedOpRewards] = responses.map((d) => {
            const reward = d.claims[walletAddress];
            return reward ? weiFromWei(reward.amount) : ZERO_WEI;
        });
        return {
            estimatedKwentaRewards,
            estimatedArbRewards: estimatedArbRewards ?? ZERO_WEI,
            estimatedOpRewards: estimatedOpRewards ?? ZERO_WEI,
        };
    }
    async getClaimableArbRewards() {
        const { MultipleMerkleDistributorArb } = this.sdk.context.contractConfigs[42161]?.kwenta || {};
        const { walletAddress } = this.sdk.context;
        if (!MultipleMerkleDistributorArb) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        const epochPeriod = getEpochPeriod(EPOCH_CONFIGS.arbitrum);
        if (epochPeriod < 0) {
            return { claimableRewards: [], totalRewards: wei(0) };
        }
        const periods = Array.from(new Array(Number(epochPeriod)), (_, i) => i);
        const adjustedPeriods = periods.slice(0, ARB_TRADING_REWARDS_END_EPOCH);
        const fileNames = adjustedPeriods.map((i) => `epoch-${i}-arb.json`);
        const responses = await Promise.all(fileNames.map(async (fileName, period) => {
            const response = await awsClient.get(fileName);
            return { ...response.data, period };
        }));
        const rewards = responses
            .map((d) => {
            const reward = d.claims[walletAddress];
            if (reward) {
                return {
                    index: reward.index,
                    account: walletAddress,
                    amount: weiFromWei(reward.amount),
                    merkleProof: reward.proof,
                    epoch: d.period,
                };
            }
            return null;
        })
            .filter((x) => !!x);
        const claimed = (await this.arbitrumClient.multicall({
            allowFailure: false,
            contracts: rewards.map((reward) => ({
                ...MultipleMerkleDistributorArb,
                functionName: 'isClaimed',
                args: [reward.index, reward.epoch],
            })),
        }));
        const { totalRewards, claimableRewards } = rewards.reduce((acc, next, i) => {
            if (!claimed[i]) {
                acc.claimableRewards.push(next);
                acc.totalRewards = acc.totalRewards.add(next.amount);
            }
            return acc;
        }, { claimableRewards: [], totalRewards: wei(0) });
        return { claimableRewards, totalRewards };
    }
    async getClaimableOpReferralRewards() {
        const { MultipleMerkleDistributorOpReferral } = this.contractConfigs.kwenta;
        const { walletAddress } = this.sdk.context;
        if (!MultipleMerkleDistributorOpReferral) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        const epochPeriod = getEpochPeriod(EPOCH_CONFIGS.opReferral);
        if (epochPeriod < 0) {
            return { claimableRewards: [], totalRewards: wei(0) };
        }
        const periods = Array.from(new Array(Number(epochPeriod)), (_, i) => i);
        const fileNames = periods.map((i) => `epoch-${i}-op-referral.json`);
        const responses = await Promise.all(fileNames.map(async (fileName, period) => {
            const response = await awsClient.get(fileName);
            return { ...response.data, period };
        }));
        const rewards = responses
            .map((d) => {
            const reward = d.claims[walletAddress];
            if (reward) {
                return {
                    index: reward.index,
                    account: walletAddress,
                    amount: weiFromWei(reward.amount),
                    merkleProof: reward.proof,
                    epoch: d.period,
                };
            }
            return null;
        })
            .filter((x) => !!x);
        const claimed = (await this.client.multicall({
            allowFailure: false,
            contracts: rewards.map((reward) => ({
                ...MultipleMerkleDistributorOpReferral,
                functionName: 'isClaimed',
                args: [reward.index, reward.epoch],
            })),
        }));
        const { totalRewards, claimableRewards } = rewards.reduce((acc, next, i) => {
            if (!claimed[i]) {
                acc.claimableRewards.push(next);
                acc.totalRewards = acc.totalRewards.add(next.amount);
            }
            return acc;
        }, { claimableRewards: [], totalRewards: wei(0) });
        return { claimableRewards, totalRewards };
    }
    async getClaimableRewards(epochPeriod) {
        const { MultipleMerkleDistributorPerpsV2 } = this.contractConfigs.kwenta;
        const { walletAddress } = this.sdk.context;
        if (!MultipleMerkleDistributorPerpsV2) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        const periods = Array.from(new Array(Number(epochPeriod)), (_, i) => i);
        const adjustedPeriods = periods.slice(TRADING_REWARDS_CUTOFF_EPOCH);
        const fileNames = adjustedPeriods.map((i) => `epoch-${i}.json`);
        const responses = await Promise.all(fileNames.map(async (fileName, index) => {
            const response = await awsClient.get(fileName);
            const period = index + TRADING_REWARDS_CUTOFF_EPOCH;
            return { ...response.data, period };
        }));
        const rewards = responses
            .map((d) => {
            const reward = d.claims[walletAddress];
            if (reward) {
                return {
                    index: reward.index,
                    account: walletAddress,
                    amount: wei(reward.amount),
                    merkleProof: reward.proof,
                    epoch: d.period,
                };
            }
            return null;
        })
            .filter((x) => !!x);
        const claimed = (await this.client.multicall({
            allowFailure: false,
            contracts: rewards.map((reward) => ({
                ...MultipleMerkleDistributorPerpsV2,
                functionName: 'isClaimed',
                args: [reward.index, reward.epoch],
            })),
        }));
        const { totalRewards, claimableRewards } = rewards.reduce((acc, next, i) => {
            if (!claimed[i]) {
                acc.claimableRewards.push(next);
                acc.totalRewards = acc.totalRewards.add(weiFromWei(next.amount));
            }
            return acc;
        }, { claimableRewards: [], totalRewards: wei(0) });
        return { claimableRewards, totalRewards };
    }
    async getClaimableKwentaRewards(epochPeriod) {
        const { MultipleMerkleDistributorStakingV2 } = this.contractConfigs.kwenta;
        const { walletAddress } = this.sdk.context;
        if (!MultipleMerkleDistributorStakingV2) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        const periods = Array.from(new Array(Number(epochPeriod)), (_, i) => i);
        const adjustedPeriods = periods.slice(STAKING_V2_REWARDS_CUTOFF_EPOCH, STAKING_V2_REWARDS_END_EPOCH);
        const fileNames = adjustedPeriods.map((i) => `epoch-${i}.json`).filter(Boolean);
        const responses = await Promise.all(fileNames.map(async (fileName, index) => {
            try {
                const response = await awsClient.get(fileName);
                const period = index + STAKING_V2_REWARDS_CUTOFF_EPOCH;
                return { ...response.data, period };
            }
            catch (err) {
                this.sdk.context.logError(err);
                return null;
            }
        }));
        const rewards = responses
            .filter(Boolean)
            .map((d) => {
            const reward = d.claims[walletAddress];
            if (reward) {
                return {
                    index: reward.index,
                    account: walletAddress,
                    amount: wei(hexToBigInt(reward.amount)),
                    merkleProof: reward.proof,
                    epoch: d.period,
                };
            }
            return null;
        })
            .filter((x) => !!x);
        const claimed = (await this.client.multicall({
            allowFailure: false,
            contracts: rewards.map((reward) => {
                return {
                    ...MultipleMerkleDistributorStakingV2,
                    functionName: 'isClaimed',
                    args: [reward.index, reward.epoch],
                };
            }),
        }));
        const { totalRewards, claimableRewards } = rewards.reduce((acc, next, i) => {
            if (!claimed[i]) {
                acc.claimableRewards.push(next);
                acc.totalRewards = acc.totalRewards.add(next.amount);
            }
            return acc;
        }, { claimableRewards: [], totalRewards: wei(0) });
        return { claimableRewards, totalRewards };
    }
    async getKwentaRewardsByEpoch(epochPeriod) {
        const { walletAddress } = this.sdk.context;
        const fileName = `epoch-${epochPeriod}.json`;
        try {
            const response = await awsClient.get(fileName);
            const rewards = response.data.claims[walletAddress];
            return rewards ? weiFromWei(rewards.amount) : ZERO_WEI;
        }
        catch (err) {
            this.sdk.context.logError(err);
            return ZERO_WEI;
        }
    }
    async getKwentaRewardsByTraders(epochPeriod, traders) {
        const periods = Array.from(new Array(Number(epochPeriod)), (_, i) => i);
        const adjustedPeriods = periods.slice(REFERRAL_PROGRAM_START_EPOCH, REFERRAL_PROGRAM_END_EPOCH);
        const fileNames = adjustedPeriods.map((i) => `epoch-${i}.json`);
        try {
            const responses = await Promise.all(fileNames.map(async (fileName) => {
                try {
                    const response = await awsClient.get(fileName);
                    return { ...response.data };
                }
                catch (err) {
                    this.sdk.context.logError(err);
                    return null;
                }
            }));
            const rewards = traders.map((walletAddress) => {
                const lowerCaseWalletAddress = walletAddress.toLowerCase();
                return responses
                    .filter(Boolean)
                    .map(({ claims }) => {
                    const lowerCaseClaims = Object.fromEntries(Object.entries(claims).map(([key, value]) => [key.toLowerCase(), value]));
                    const reward = lowerCaseClaims[lowerCaseWalletAddress];
                    return reward ? wei(reward.amount) : wei('0');
                })
                    .reduce((acc, amount) => (amount ? acc.add(weiFromWei(amount)) : acc), ZERO_WEI);
            });
            return rewards
                .flat()
                .reduce((total, next) => (next ? total.add(weiFromWei(next)) : total), ZERO_WEI);
        }
        catch (err) {
            this.sdk.context.logError(err);
            return ZERO_WEI;
        }
    }
    async claimKwentaRewards(claimableRewards) {
        const { MultipleMerkleDistributorPerpsV2 } = this.contractConfigs.kwenta;
        if (!MultipleMerkleDistributorPerpsV2) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        return this.sdk.transactions.writeContract({
            ...MultipleMerkleDistributorPerpsV2,
            functionName: 'claimMultiple',
            args: [
                claimableRewards.map((r) => ({
                    ...r,
                    amount: r.amount.toBigInt(),
                    index: BigInt(r.index),
                    epoch: BigInt(r.epoch),
                })),
            ],
        });
    }
    async claimMultipleAllRewards(claimableRewards) {
        const { BatchClaimer, MultipleMerkleDistributorStakingV2 } = this.contractConfigs.kwenta;
        if (!BatchClaimer || !MultipleMerkleDistributorStakingV2) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        return this.switchToOptimismAndWriteContact({
            ...BatchClaimer,
            functionName: 'claimMultiple',
            args: [
                [MultipleMerkleDistributorStakingV2.address],
                claimableRewards.map((rewards) => rewards.map((r) => ({
                    ...r,
                    amount: r.amount.toBigInt(),
                    index: BigInt(r.index),
                    epoch: BigInt(r.epoch),
                }))),
            ],
        });
    }
    async claimOpRewards(claimableRewards, isSnx = false) {
        const { MultipleMerkleDistributorOp, MultipleMerkleDistributorSnxOp } = this.contractConfigs.kwenta;
        if (!MultipleMerkleDistributorOp || !MultipleMerkleDistributorSnxOp) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        const contract = isSnx ? MultipleMerkleDistributorSnxOp : MultipleMerkleDistributorOp;
        return this.sdk.transactions.writeContract({
            ...contract,
            functionName: 'claimMultiple',
            args: [
                claimableRewards.map((r) => ({
                    ...r,
                    amount: r.amount.toBigInt(),
                    index: BigInt(r.index),
                    epoch: BigInt(r.epoch),
                })),
            ],
        });
    }
    async claimOpReferralRewards(claimableRewards) {
        const { MultipleMerkleDistributorOpReferral } = this.contractConfigs.kwenta;
        if (!MultipleMerkleDistributorOpReferral) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        return this.switchToOptimismAndWriteContact({
            ...MultipleMerkleDistributorOpReferral,
            functionName: 'claimMultiple',
            args: [
                claimableRewards.map((r) => ({
                    ...r,
                    amount: r.amount.toBigInt(),
                    index: BigInt(r.index),
                    epoch: BigInt(r.epoch),
                })),
            ],
        });
    }
    async claimArbRewards(claimableRewards) {
        const { MultipleMerkleDistributorArb } = this.sdk.context.contractConfigs[42161]?.kwenta || {};
        if (!MultipleMerkleDistributorArb) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        return this.sdk.transactions.writeContract({
            ...MultipleMerkleDistributorArb,
            functionName: 'claimMultiple',
            args: [
                claimableRewards.map((r) => ({
                    ...r,
                    amount: r.amount.toBigInt(),
                    index: BigInt(r.index),
                    epoch: BigInt(r.epoch),
                })),
            ],
        }, arbitrum.id);
    }
    async getFuturesFee(start, end, chainId) {
        const response = await getFuturesAggregateStats(getPerpsV2SubgraphUrl(chainId), {
            first: Number(DEFAULT_NUMBER_OF_FUTURES_FEE),
            where: {
                asset: AGGREGATE_ASSET_KEY,
                period: SECONDS_PER_DAY,
                timestamp_gte: start,
                timestamp_lt: end,
            },
            orderDirection: 'desc',
            orderBy: 'timestamp',
        });
        return response ? calculateTotalFees(response) : wei(0);
    }
    async getFuturesFeeForAccount(account, start, end, chainId) {
        if (!account)
            return wei(0);
        const response = await getFuturesTrades(getPerpsV2SubgraphUrl(chainId), {
            first: Number(DEFAULT_NUMBER_OF_FUTURES_FEE),
            where: {
                account: account,
                timestamp_gt: start,
                timestamp_lt: end,
                trackingCode: KWENTA_TRACKING_CODE,
            },
            orderDirection: 'desc',
            orderBy: 'timestamp',
        });
        return response ? calculateFeesForAccount(response) : wei(0);
    }
    async performStakeAction(action, amount, options = { escrow: false, version: 1 }) {
        const { RewardEscrow, KwentaStakingRewards, KwentaStakingRewardsV2 } = this.contractConfigs.kwenta;
        if (!RewardEscrow || !KwentaStakingRewards || !KwentaStakingRewardsV2) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        const contract = options.version === 1
            ? options?.escrow
                ? RewardEscrow
                : KwentaStakingRewards
            : KwentaStakingRewardsV2;
        return this.switchToOptimismAndWriteContact({
            ...contract,
            functionName: `${action}${options?.escrow ? 'Escrow' : ''}`,
            args: [amount],
        });
    }
    async approveOperator(delegatedAddress, isApproval) {
        const { KwentaStakingRewardsV2 } = this.contractConfigs.kwenta;
        if (!KwentaStakingRewardsV2) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        return this.switchToOptimismAndWriteContact({
            ...KwentaStakingRewardsV2,
            functionName: 'approveOperator',
            args: [delegatedAddress, isApproval],
        });
    }
    async getApprovedOperators() {
        const { walletAddress } = this.sdk.context;
        return queryOperatorsByOwner(this.sdk, walletAddress);
    }
    async transferFrom(from, to, id) {
        const { RewardEscrowV2 } = this.contractConfigs.kwenta;
        if (!RewardEscrowV2) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        return this.switchToOptimismAndWriteContact({
            ...RewardEscrowV2,
            functionName: 'transferFrom',
            args: [from, to, id],
        });
    }
    async bulkTransferFrom(from, to, ids) {
        const { RewardEscrowV2 } = this.contractConfigs.kwenta;
        if (!RewardEscrowV2) {
            throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
        }
        return this.switchToOptimismAndWriteContact({
            ...RewardEscrowV2,
            functionName: 'bulkTransferFrom',
            args: [from, to, ids],
        });
    }
}
